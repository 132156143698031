
<div class="col-lg-12 mb-4 table-responsive" *ngIf="!isprocessing">
    <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="myform5" #myform5="ngForm"
        appFocusInvalidInput novalidate>
        <div class="multisteps-form__panel shadow rounded bg-white js-active" data-animation="fadeIn">
            <div class="multisteps-form__content">
                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text">
                                    Order Confirmation
                                </h3>
                            </div>
                        </div>
                        <div class="m-portlet__head-tools">
                            <button *ngIf="this.UserRole == roleOfAdmin"
                                class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                (click)="SendBack()">Send Back</button>
                        </div>
                    </div>
                    <div class="markedFields">
                        <span style="margin-left:10px;">* Marked fields are
                            mandatory</span>
                    </div>
                    <div class="m-portlet__body">
                        <div class="form-group m-form__group row">
                            <mat-radio-group class="mobileView" [disabled]="!isEnquiryConfirmationEditable"
                                [(ngModel)]="chosenItem" name="radio" (change)="radioChange(chosenItem)"
                                aria-label="Select an option">
                                <mat-radio-button value="1">
                                    Enquiry
                                    Confirmation</mat-radio-button>
                                &nbsp;&nbsp;
                                <mat-radio-button value="2">Quotation
                                    Revision
                                </mat-radio-button>
                            </mat-radio-group><br /><br />
                        </div>

                        <div *ngIf="chosenItem=='2' && isEnquiryConfirmationEditable"
                            class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Comments:<span
                                    style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" id="eccomments" name="eccomments" rows="3"
                                    maxlength="1500" [disabled]="!isEnquiryConfirmationEditable"
                                    [(ngModel)]="quoterevcomments" (keyup)="limitTextOnKeyUpDown1($event)"
                                    (keypress)="limitTextOnKeyUpDown1($event)" #eccomments="ngModel"
                                    [ngClass]="{ 'is-invalid': (myform5.submitted && eccomments.invalid) || (eccomments?.touched && eccomments.invalid) }"
                                    required>
                    </textarea>
                                <span> Limit {{maxLimit}} characters,
                                    {{remLimit}} characters
                                    remaining.</span>
                                <div *ngIf="(myform5.submitted && eccomments.invalid) || (eccomments?.touched && eccomments.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="eccomments.errors.required">
                                        Comment is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="chosenItem=='1'">
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">View
                                    Quotation:</label>
                                <div class="col-lg-3 pt-2 filenamemobile">
                                    <a target="_blank"
                                        href="{{QuotationDownloadPath}}{{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}">
                                        {{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}
                                    </a>
                                </div>
                                <div class="offset-1"></div>
                                <label class="col-lg-2 col-form-label">
                                    BOM (Bill Of Materials):</label>
                                <div class="col-lg-3 pt-2 filenamemobile">
                                    <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.BOM_filepath}}"
                                        target="_blank">{{EnquiryObj.EnquiryDetails.BOM_filename}}</a>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Enquiry
                                    Status:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <select class="form-control " id="enquirystatus" name="enquirystatus"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.EnquiryConfirmStatus"
                                        #enquirystatus="ngModel" [disabled]="!isEnquiryConfirmationEditable"
                                        (change)="onEnquiryConfirmationStatusChange()"
                                        [ngClass]="{ 'is-invalid': (myform5.submitted && enquirystatus.invalid) || (enquirystatus?.touched && enquirystatus.invalid)}"
                                        required>
                                        <option value=null disabled>
                                            Select Status</option>
                                        <option value="Won">Won</option>
                                        <option value="Lost">Lost</option>
                                    </select>
                                    <div *ngIf="(myform5.submitted && enquirystatus.invalid) || (enquirystatus?.touched && enquirystatus.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="enquirystatus.errors.required">
                                            Enquiry status is
                                            required
                                        </div>
                                    </div>
                                </div>
                                <div class="offset-1" *ngIf="!disableWonCategory"></div>

                                <label class="col-lg-2 col-form-label" *ngIf="!disableWonCategory">Reason
                                    Category:<span style="color:#f4516c;">&nbsp;*</span></label>

                                <div class="col-lg-3" *ngIf="!disableWonCategory">
                                    <select class="form-control " id="reasoncategory" name="reasoncategory"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.ReasonCategory" #reasoncategory="ngModel"
                                        [ngClass]="{ 'is-invalid':myform5.submitted  && reasoncategory.invalid || reasoncategory?.touched && reasoncategory.invalid}"
                                        (change)="onEnquiryReasonCategoryChange(EnquiryObj.EnquiryDetails.ReasonCategory)"
                                        [disabled]="!isEnquiryConfirmationEditable" required>
                                        <option value=null disabled>Select Reason Category
                                        </option>
                                        <option *ngFor="let item of CategoryRegion" value="{{item.ReasonCategoryId}}">
                                            {{item.ReasonCategory}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback"
                                        *ngIf="myform5.submitted && reasoncategory.invalid || reasoncategory?.touched && reasoncategory.invalid">
                                        <div *ngIf="myform5.hasError('required', 'reasoncategory')">
                                            Reason Category is required
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Reason:
                                    <span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <select class="form-control " name="EnquiryConfirmReason"
                                        [disabled]="!isEnquiryConfirmationEditable"
                                        [ngClass]="{ 'is-invalid': (myform5.submitted && EnquiryConfirmReason.invalid) || (EnquiryConfirmReason?.touched && EnquiryConfirmReason.invalid)}"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.EnquiryConfirmReason"
                                        (change)="onEnquiryReasonChange(EnquiryObj.EnquiryDetails.EnquiryConfirmReason)"
                                        #EnquiryConfirmReason="ngModel" id="stdreason" required>
                                        <option value=null selected disabled>
                                            Select Reason
                                        </option>
                                        <option *ngFor="let item of WinLossDropdownDataForStatus;let i = index"
                                            value="{{item.Id}}">
                                            {{item.Value}}
                                        </option>
                                    </select>
                                    <div *ngIf="(myform5.submitted && EnquiryConfirmReason.invalid) || (EnquiryConfirmReason?.touched && EnquiryConfirmReason.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="EnquiryConfirmReason.errors.required">
                                            Reason is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">

                                <label class="col-lg-2 col-form-label">PO
                                    Value:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <input type="text" class="form-control m-input" placeholder="Enter PO Value"
                                        maxlength="11" min="0" pattern="[0-9]+" id="povalue" name="povalue"
                                        [disabled]="isPoValueDisable || !isEnquiryConfirmationEditable"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.POValue"
                                        (keypress)="AvoidSpace($event);isNumberKey($event);" #povalue="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform5.submitted && povalue.invalid) || (povalue?.touched && povalue.invalid) }"
                                        required>
                                    <div *ngIf="(myform5.submitted && povalue.invalid) || (povalue?.touched && povalue.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="myform5.hasError('pattern', 'povalue')">
                                            PO Value must be an integer
                                        </div>
                                        <div *ngIf="povalue.errors.required">
                                            PO value is
                                            required
                                        </div>
                                    </div>
                                </div>
                                <div class="offset-1"></div>
                                <label *ngIf="EnquiryObj.EnquiryDetails.EnquiryConfirmStatus!='Lost'"
                                    class="col-lg-2 col-form-label">Attach
                                    PO/LOI:<span style="color:#f4516c;">&nbsp;*</span></label>

                                <div *ngIf="(EnquiryObj.EnquiryDetails.PO_filename==undefined ||
                                    EnquiryObj.EnquiryDetails.PO_filename=='' || 
                                    EnquiryObj.EnquiryDetails.PO_filename==null) && EnquiryObj.EnquiryDetails.EnquiryConfirmStatus!='Lost'"
                                    class="col-lg-4 pt-1">
                                    <div class="file btn btn-sm btn-warning">
                                        Attach PO/LOI
                                        <input type="file" class="form-control-file" id="ecfile" name="ecfile"
                                            (change)="editFileDetails($event,0)" required />
                                    </div>
                                    <br /><span
                                        *ngIf="!(isEnquiryConfirmationEditable && !(EnquiryObj.EnquiryDetails.POAttchments == undefined || EnquiryObj.EnquiryDetails.POAttchments?.length == 0))"
                                        class="fileext">
                                        {{AppGlobals.supportedFileFormatsForOtherAttachments}}</span>
                                    <div *ngIf="myform5.submitted && EnquiryObj.EnquiryDetails.EnquiryConfirmStatus=='Won' && EnquiryObj.EnquiryDetails.POAttchments == undefined"
                                        style="color:#f4516c;font-size: 80%;">
                                        PO attachment is
                                        required
                                    </div>
                                    <div class="filenamemobile">
                                        {{EnquiryObj.EnquiryDetails.POAttchments
                                        != undefined &&
                                        EnquiryObj.EnquiryDetails.POAttchments[0]
                                        != undefined ?
                                        EnquiryObj.EnquiryDetails.POAttchments[0].name:
                                        ""}}
                                        <i class="flaticon-delete"
                                            *ngIf="isEnquiryConfirmationEditable && !(EnquiryObj.EnquiryDetails.POAttchments == undefined || EnquiryObj.EnquiryDetails.POAttchments?.length == 0)"
                                            style="color:#f4516c" (click)="deletePOAttachment(0)"
                                            title="Remove"><br></i>
                                    </div>

                                </div>
                                <div class="col-lg-3 pt-2 filenamemobile" *ngIf="!(EnquiryObj.EnquiryDetails.PO_filename==undefined ||
                            EnquiryObj.EnquiryDetails.PO_filename=='' || 
                            EnquiryObj.EnquiryDetails.PO_filename==null)">
                                    <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.PO_filepath}}"
                                        target="_blank">{{EnquiryObj.EnquiryDetails.PO_filename}}</a>
                                </div>


                            </div>

                            <div class="form-group m-form__group row">
                                <h5
                                    style="border-bottom: 1px solid #ccc; margin-top: 15px;padding-bottom: 10px; width: 100%;">
                                    Application</h5>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Industry
                                    Segment:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <select class="form-control p-0" id="industrysegment" name="industrysegment"
                                        #industrysegment="ngModel"
                                        (change)="onIndustrySegmentSelect(industrysegment.value)"
                                        [ngClass]="{ 'is-invalid':myform5.submitted  && industrysegment.invalid || industrysegment?.touched && industrysegment.invalid}"
                                        required [disabled]="!isEnquiryConfirmationEditable"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.segment">
                                        <option value="null" disabled>
                                            Select
                                            Industry Segment</option>
                                        <option *ngFor="let item of IndustrySegment" value="{{item.Id}}">
                                            {{item.Name}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback"
                                        *ngIf="myform5.submitted && industrysegment.invalid || industrysegment?.touched && industrysegment.invalid">
                                        <div *ngIf="myform5.hasError('required', 'industrysegment')">
                                            Industry segment is required
                                        </div>
                                    </div>
                                </div>

                                <div class="offset-1"></div>

                                <label class="col-lg-2 col-form-label">Sub
                                    Segment:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <select class="form-control p-0" id="subsegment" name="subsegment"
                                        #subsegment="ngModel"
                                        [ngClass]="{ 'is-invalid':myform5.submitted  && subsegment.invalid || subsegment?.touched && subsegment.invalid}"
                                        required [disabled]="!isEnquiryConfirmationEditable"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.subSegment"
                                        (change)="onSubSegmentSelect(subsegment.value)">
                                        <option value="null" disabled>
                                            Select
                                            Sub Segment</option>
                                        <option *ngFor="let item of SubSegment" value="{{item.Id}}">
                                            {{item.Name}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback"
                                        *ngIf="myform5.submitted && subsegment.invalid || subsegment?.touched && subsegment.invalid">
                                        <div *ngIf="myform5.hasError('required', 'subsegment')">
                                            Sub segment is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Application
                                    Name:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <select class="form-control p-0" id="appplication" name="appplication"
                                        #appplication="ngModel"
                                        [ngClass]="{ 'is-invalid':myform5.submitted  && appplication.invalid || appplication?.touched && appplication.invalid}"
                                        required [disabled]="!isEnquiryConfirmationEditable"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.application">
                                        <option value="null" disabled>
                                            Select
                                            Application Name</option>
                                        <option *ngFor="let item of Application" value="{{item.Id}}">

                                            {{item.Application}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback"
                                        *ngIf="myform5.submitted && appplication.invalid || appplication?.touched && appplication.invalid">
                                        <div *ngIf="appplication.errors.required">
                                            Application name is required
                                        </div>
                                    </div>

                                </div>

                                <div class="offset-1"></div>

                                <label class="col-lg-2 col-form-label">End
                                    Customer:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <input class="form-control m-input" type="text" name="endcustomer"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.EndCustomer" #endcustomer="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform5.submitted && endcustomer.invalid) || (endcustomer?.touched && endcustomer.invalid) }"
                                        placeholder="Enter End Customer" maxlength="50"
                                        (keyup)="limitTextOnKeyUpDown2($event)"
                                        (keypress)="limitTextOnKeyUpDown2($event)"
                                        [disabled]="!isEnquiryConfirmationEditable" required>
                                    <span> Limit {{maxCharLimit}}
                                        characters,
                                        {{remCharLimit}} characters
                                        remaining.</span>
                                    <div *ngIf="(myform5.submitted && endcustomer.invalid) || (endcustomer?.touched && endcustomer.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="endcustomer.errors.required">
                                            End Customer is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Application
                                    Description:<span style="color:#f4516c;">&nbsp;*</span></label>

                                <div class="col-lg-9">
                                    <textarea class="form-control m-input" id="applicationDescription"
                                        name="applicationDescription" rows="3" maxlength="1500"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.ApplicationDescription"
                                        (keyup)="limitTextOnKeyUpDown3($event)"
                                        (keypress)="limitTextOnKeyUpDown3($event)" #applicationDescription="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform5.submitted && applicationDescription.invalid) || (applicationDescription?.touched && applicationDescription.invalid) }"
                                        [disabled]="!isEnquiryConfirmationEditable" required>
                                </textarea>
                                    <span> Limit {{maxappdescLimit}}
                                        characters,
                                        {{remappdescLimit}} characters
                                        remaining.</span>
                                    <div *ngIf="(myform5.submitted && applicationDescription.invalid) || (applicationDescription?.touched && applicationDescription.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="applicationDescription.errors.required">
                                            Application Description is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <h5
                                    style="border-bottom: 1px solid #ccc; margin-top: 12px;padding-bottom: 10px; width: 100%;">
                                    Competitor Details
                                </h5>
                            </div>

                            <div class="table-responsive">
                                <table class="table order-list table-striped- table-bordered table-hover table-edit"
                                    id="enquiryconfirmationtable1">
                                    <thead>
                                        <tr>
                                            <th class="text-center">
                                                Competitor Name
                                            </th>
                                            <th class="text-center">
                                                Model Number
                                            </th>
                                            <th class="text-center">
                                                Competitor Price
                                            </th>
                                            <th class="text-center" *ngIf="isEnquiryConfirmationEditable">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <ng-container
                                            *ngFor="let field of EnquiryObj.CompetitorDetailObj; let i = index;">
                                            <tr>
                                                <td>

                                                    <input type="text" class="form-control input-sm"
                                                        id="competitorname{{i}}" name="competitorname{{i}}"
                                                        [(ngModel)]="field.CompetitorName" #competitorname="ngModel"
                                                        placeholder="Enter Competitor Name"
                                                        [disabled]="!isEnquiryConfirmationEditable"
                                                        [required]="isCompetitorRequired">

                                                    <div *ngIf="isCompetitorRequired && competitorname.invalid">
                                                        <span class="validationmsg">
                                                            Competitor Name is required
                                                        </span>
                                                    </div>

                                                </td>
                                                <td>
                                                    <input type="text" class="form-control input-sm "
                                                        #modelname="ngModel" id="modelname{{i}}" name="modelname{{i}}"
                                                        [(ngModel)]="field.ModelName" placeholder="Enter Model Name"
                                                        [disabled]="!isEnquiryConfirmationEditable"
                                                        [required]="isCompetitorRequired">
                                                    <div *ngIf="isCompetitorRequired && modelname.invalid">
                                                        <span class="validationmsg">
                                                            Model Name is required
                                                        </span>
                                                    </div>

                                                </td>
                                                <td>
                                                    <input type="number" class="form-control input-sm "
                                                        #competitorprice="ngModel" id="competitorprice{{i}}"
                                                        name="competitorprice{{i}}" [(ngModel)]="field.Price"
                                                        placeholder="Enter Price"
                                                        [disabled]="!isEnquiryConfirmationEditable"
                                                        [required]="isCompetitorRequired" maxlength="7" pattern="[0-9]+"
                                                        (blur)="competitorprice.invalid && competitorprice.value % 1 !== 0 ? competitorprice.control.setErrors({'invalidInteger': true}) : competitorprice.control.setErrors(null)">
                                                    <div *ngIf="isCompetitorRequired && competitorprice.invalid">
                                                        <span class="validationmsg"
                                                            *ngIf="competitorprice.errors?.required">Price is
                                                            required</span>
                                                        <span class="validationmsg"
                                                            *ngIf="competitorprice.errors?.invalidInteger">Please enter
                                                            an integer value</span>
                                                    </div>
                                                </td>
                                                <td *ngIf="isEnquiryConfirmationEditable"
                                                    style="padding:5px!Important;">
                                                    <div style="display: inline-flex;">

                                                        <a title="Add" (click)="AddNewRow(i)"
                                                            *ngIf=" i == EnquiryObj.CompetitorDetailObj.length - 1"
                                                            style="color:#000000;width: 25px;height: 25px;"
                                                            class="btn btn-primary m-btn m-btn--icon m-btn--icon-only mr-2"><i
                                                                class="fa fa-plus"></i></a>

                                                        <a title="Delete"
                                                            *ngIf="(EnquiryObj.CompetitorDetailObj.length>1) && ((EnquiryObj.CompetitorDetailObj.length - 1 ) == i)"
                                                            (click)="deleterow(i)"
                                                            style="color:#ffffff;width: 25px;height: 25px;"
                                                            class="btn btn-metal m-btn m-btn--icon m-btn--icon-only mr-2"><i
                                                                class="fa fa-minus"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>

                                    </tbody>
                                </table>
                            </div>

                            <div *ngIf="isEnquiryConfirmationEditable" class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Comments:<span
                                        style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-9">
                                    <textarea class="form-control m-input" id="eccomments" name="eccomments" rows="3"
                                        maxlength="1500" [disabled]="!isEnquiryConfirmationEditable"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.Comments"
                                        (keyup)="limitTextOnKeyUpDown1($event)"
                                        (keypress)="limitTextOnKeyUpDown1($event)" #eccomments="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform5.submitted && eccomments.invalid) || (eccomments?.touched && eccomments.invalid) }"
                                        required>
                            </textarea>
                                    <span> Limit {{maxLimit}} characters,
                                        {{remLimit}} characters
                                        remaining.</span>
                                    <div *ngIf="(myform5.submitted && eccomments.invalid) || (eccomments?.touched && eccomments.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="eccomments.errors.required">
                                            Comment is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button-row text-right mt-4" style="margin:15px;">
                    <div class="m-portlet__head-caption">
                        <span *ngFor="let item of filterRule;let i = index;">
                            <button class="btn btn-primary ml-auto js-btn-next btnspc"
                                type="{{item.workflowActionType}}"
                                (click)="OnWorkflowAction(myform5,item.workflowActionId)">
                                {{item.workflowActionName}}
                            </button> &nbsp; &nbsp;
                        </span>
                        <!-- <button *ngIf = "this.UserRole == roleOfAdmin"
                        class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                    (click)="SendBack()">Send Back</button> &nbsp; &nbsp; -->
                        <button class="btn btn-metal m-btn m-btn--air m-btn--custom" type="button"
                            (click)="backtoList()" routerLinkActive="active">Cancel</button>
                    </div>


                </div>
            </div>
        </div>
    </form>

</div>
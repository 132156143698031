<loader [showLoader]="showLoader"></loader>
<div class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	
                            m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption  col-lg-6">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Edit/View Enquiry
                                    </h3>
                                </div>                               
                            </div>

                            <div class="col-lg-6 ng-container salute" *ngIf="showReminder">                              
                                <form #myform2="ngForm" name="myform2"
                                    (ngSubmit)="myform2.form.valid && AddReminderDays(myform2)">                           
                                    <div class="row align-items-center d-flex" style=" margin-right: -50%;">
                                        <div class="col-sm-3">
                                            <label class="mb-0" style="color:white;font-size: 1.3rem;font-weight: 500;">Postpone Escalation: <span
                                                    style="color:darkred;">&nbsp;*</span></label>
                                        </div>

    
                                        <div class="col-sm-3 ">
                                            <select class="form-control " id="sel1" name="reminder"
                                                [(ngModel)]="AlertReminderDays" #reminder="ngModel"
                                                [ngClass]="{ 'is-invalid':myform2.submitted  && reminder.invalid || reminder?.touched && reminder.invalid}"
                                                required>
                                                <option value=undefined disabled>Select Days</option>
                                                <option *ngFor="let item of ReminderDays" value="{{item}}">{{item}}
                                                </option>
                                            </select>
                                            <div class="invalid-feedback" style="color: darkred; font-weight: 100;"
                                                *ngIf="myform2.submitted && reminder.invalid || reminder.touched && reminder.invalid">
                                                <div *ngIf="myform2.hasError('required', 'reminder')">
                                                    This is required </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 ">
                                            <button class="btn btn-primary ml-auto" type="submit">Submit</button>
                                        </div>
                                    </div>                                
                                </form>
                            </div>                           
                        </div> 
                        
                        <div class="m-portlet__body">
                            <div class="multisteps-form">
                                <div class="row mt-3">
                                    <div class="col-lg-12 mb-4 table-responsive" *ngIf="!isprocessing">
                                        <mat-horizontal-stepper labelPosition="bottom" #stepper
                                            style="margin-bottom: -60px;" [selectedIndex]="selectedIndexForStatus">

                                         

                                            <mat-step *ngIf="isEnquiryFormVisible">
                                                <div><button hidden="true" #buttonclk (click)="checkStep(stepper)">Hit by TS file Only to run steper</button></div>
                                                <ng-template matStepLabel><span data-toggle="m-tooltip"
                                                        data-placement="bottom" title="Enquiry"
                                                        data-original-title="Enquiry">Enquiry</span>
                                                </ng-template>
                                                <div class="col-lg-12 mb-4 table-responsive">
                                                    <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="myform1" #myform1="ngForm"
                                                        appFocusInvalidInput novalidate>
                                                        <div class="multisteps-form__panel shadow rounded bg-white js-active" data-animation="fadeIn">
                                                            <div class="multisteps-form__content">
                                                    
                                                                <div class="markedFields">
                                                                    <span style="margin-left:10px;">* Marked fields are
                                                                        mandatory</span>
                                                                </div>
                                                                <div>
                                                                    <div class="form-group m-form__group row">
                                                                        <label class="col-lg-2 col-form-label">
                                                                            <h5>User Name:</h5>
                                                                        </label>
                                                                        <div class="col-lg-3 pt-3">
                                                                            <h6> {{EnquiryObj.EnquiryDetails.InitiatorName}}
                                                                            </h6>
                                                                        </div>
                                                                        <div class="offset-1"></div>
                                                                        <label class="col-lg-2 col-form-label">
                                                                            <h5>Role:</h5>
                                                                        </label>
                                                                        <div class="col-lg-3 pt-3">
                                                                            <h6>{{EnquiryObj.EnquiryDetails.RoleName}}</h6>
                                                                            &nbsp;&nbsp;
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    
                                                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                                                    <div class="m-portlet__head">
                                                                        <div class="m-portlet__head-caption">
                                                                            <div class="m-portlet__head-title">
                                                                                <h3 class="m-portlet__head-text">
                                                                                    Customer Details
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="m-portlet__body">
                                                    
                                                                        <div id="dvTestData">
                                                    
                                                                            <div class="form-group m-form__group row">
                                                                                <label class="col-lg-2 col-form-label">Customer
                                                                                    Name:<span style="color:#f4516c;">&nbsp;*</span></label>
                                                    
                                                                                <div class="col-lg-3 pt-3">
                                                                                    {{EnquiryObj.EnqCustomerDetails.CustomerName}}
                                                                                </div>
                                                                                <div class="offset-1"></div>
                                                                                <label class="col-lg-2 col-form-label">Customer
                                                                                    Code:<span style="color:#f4516c;">&nbsp;*</span></label>
                                                    
                                                                                <div class="col-lg-3 pt-3">
                                                                                    {{EnquiryObj.EnqCustomerDetails.CustomerCode}}
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group m-form__group row">
                                                                                <label class="col-lg-2 col-form-label">Industry
                                                                                    Segment:<span style="color:#f4516c;">&nbsp;*</span></label>
                                                                                <div class="col-lg-3 pt-3">
                                                                                    {{EnquiryObj.EnqCustomerDetails.IndustrySegment}}
                                                                                </div>
                                                                                <div class="offset-1"></div>
                                                                                <label class="col-lg-2 col-form-label">Type
                                                                                    of Customer:<span style="color:#f4516c;">&nbsp;*</span></label>
                                                                                <div class="col-lg-3 pt-3">
                                                                                    {{EnquiryObj.EnqCustomerDetails.CustomerType}}
                                                                                </div>
                                                    
                                                                            </div>
                                                                            <div class="form-group m-form__group row">
                                                                                <label class="col-lg-2 col-form-label">Customer
                                                                                    Category:<span style="color:#f4516c;">&nbsp;*</span></label>
                                                                                <div class="col-lg-3 pt-3">
                                                                                    {{EnquiryObj.EnqCustomerDetails.CustomerCategory}}
                                                                                </div>
                                                                                <div class="offset-1"></div>
                                                                                <label class="col-lg-2 col-form-label">Customer&nbsp;Classification:<span
                                                                                        style="color:#f4516c;">&nbsp;*</span></label>
                                                                                <div class="col-lg-3 pt-3">
                                                                                    {{EnquiryObj.EnqCustomerDetails.CustomerClassification}}
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group m-form__group row">
                                                    
                                                                                <label class="col-lg-2 col-form-label">Region:<span
                                                                                        style="color:#f4516c;">&nbsp;*</span></label>
                                                                                <div class="col-lg-3 pt-3">
                                                                                    {{EnquiryObj.EnqCustomerDetails.Region}}
                                                                                </div>
                                                                                <div class="offset-1"></div>
                                                                                <label class="col-lg-2 col-form-label">Enquiry
                                                                                    Source:<span style="color:#f4516c;">&nbsp;*</span></label>
                                                                                <div class="col-lg-3">
                                                                                    <select class="form-control " id="enquirysource" name="enquirysource"
                                                                                        [(ngModel)]="EnquiryObj.EnquiryDetails.EnquirySourceId" #enquirysource="ngModel"
                                                                                        [ngClass]="{ 'is-invalid': (myform1.submitted && enquirysource.invalid) || (enquirysource?.touched && enquirysource.invalid) }"
                                                                                        required disabled>
                                                                                        <option value="undefined" selected disabled>Select
                                                                                            Enquiry Source
                                                                                        </option>
                                                                                        <option *ngFor="let item of enquirySource" value="{{item.Id}}">
                                                                                            {{item.Name}}
                                                                                        </option>
                                                                                    </select>
                                                                                    <div *ngIf="(myform1.submitted && enquirysource.invalid) || (enquirysource?.touched && enquirysource.invalid)"
                                                                                        class="invalid-feedback">
                                                                                        <div *ngIf="enquirysource.errors.required">
                                                                                            Enquiry source is required
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                    
                                                    
                                                                            </div>
                                                                        </div>
                                                    
                                                                    </div>
                                                                </div>
                                                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                                                    <div class="m-portlet__head">
                                                                        <div class="m-portlet__head-caption">
                                                                            <div class="m-portlet__head-title">
                                                                                <h3 class="m-portlet__head-text">
                                                                                    Enquiry Details
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="m-portlet__body">
                                                    
                                                                        <div class="form-group m-form__group row">
                                                                            <label class="col-lg-2 col-form-label">Enquiry
                                                                                Date:<span style="color:#f4516c;">&nbsp;*</span></label>
                                                                            <div class="col-lg-3">
                                                                                <div class="input-group date">
                                                                                    <input readonly class="form-control" placeholder="DD-MMM-YYYY" (click)="ed.toggle()"
                                                                                        id="enquirydate" name="enquirydate" ngbDatepicker #ed="ngbDatepicker"
                                                                                        [(ngModel)]="EnquiryObj.EnquiryDetails.EnquiryDate" #enquirydate="ngModel"
                                                                                        [ngClass]="{ 'is-invalid': (myform1.submitted && enquirydate.invalid) || (enquirydate?.touched && enquirydate.invalid) }"
                                                                                        dateFormat="dd-MMM-yyyy" required disabled>
                                                                                    <div class="input-group-append">
                                                                                        <span (click)="ed.toggle()" class="input-group-text">
                                                                                            <i class="la la-calendar-check-o"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div *ngIf="(myform1.submitted && enquirydate.invalid) || (enquirydate?.touched && enquirydate.invalid)"
                                                                                    class="invalid-feedback" style="display: block;">
                                                                                    Date is
                                                                                    required
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                    
                                                                        <div class="form-group m-form__group row" *ngIf="EnquiryObj.EnquiryDetails.Status != undefined">
                                                                            <label class="col-lg-2 col-form-label">Enquiry
                                                                                Number:</label>
                                                                            <div class="col-lg-3 pt-3" style="padding-top:9px;">
                                                                                {{EnquiryObj.EnquiryDetails.EnquiryNumber}}
                                                                            </div>
                                                                            <div class="offset-1"></div>
                                                                            <label class="col-lg-2 col-form-label">Status:</label>
                                                                            <div class="col-lg-3 pt-3" style="padding-top:9px;">
                                                                                {{EnquiryObj.EnquiryDetails.EnquiryStatusDescription}}
                                                                            </div>
                                                                        </div>
                                                    
                                                                        <div class="form-group m-form__group row">
                                                    
                                                                            <label *ngIf="EnquiryObj.EnquiryDetails.ReasonCategory != undefined"
                                                                                class="col-lg-2 col-form-label">Reason
                                                                                Category:</label>
                                                                            <div *ngIf="EnquiryObj.EnquiryDetails.ReasonCategory != undefined" class="col-lg-3 pt-3"
                                                                                style="padding-top:9px;">
                                                                                {{EnquiryObj.EnquiryDetails.ReasonCategoryName}}
                                                                            </div>
                                                    
                                                                            <div class="offset-1"></div>
                                                    
                                                                            <label class="col-lg-2 col-form-label"
                                                                                *ngIf="EnquiryObj.EnquiryDetails.EnquiryConfirmReason != undefined">
                                                                                Reason:</label>
                                                                            <div class="col-lg-3 pt-3" style="padding-top:9px;">
                                                                                {{EnquiryObj.EnquiryDetails.EnquiryConfirmReasonName}}
                                                                            </div>
                                                                        </div>
                                                    
                                                                        <div class="form-group m-form__group row">
                                                                            <label class="col-lg-2 col-form-label">Enquiry
                                                                                Description:<span style="color:#f4516c;">&nbsp;*</span></label>
                                                                            <div class="col-lg-9">
                                                                                <textarea class="form-control m-input" id="enquirydescription" maxlength="1500"
                                                                                    name="enquirydescription" rows="3"
                                                                                    [(ngModel)]="EnquiryObj.EnquiryDetails.EnquiryDescription"
                                                                                    (keyup)="limitTextOnKeyUpDown1($event)" (keypress)="limitTextOnKeyUpDown1($event)"
                                                                                    #enquirydescription="ngModel"
                                                                                    [ngClass]="{ 'is-invalid': (myform1.submitted && enquirydescription.invalid) || (enquirydescription?.touched && enquirydescription.invalid) }"
                                                                                    required disabled></textarea>
                                                                                <span> Limit {{maxLimit}} characters,
                                                                                    {{remLimit}}
                                                                                    characters remaining.</span>
                                                                                <div *ngIf="(myform1.submitted && enquirydescription.invalid) || (enquirydescription?.touched && enquirydescription.invalid)"
                                                                                    class="invalid-feedback">
                                                                                    <div *ngIf="enquirydescription.errors.required">
                                                                                        Enquiry description is required
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                    
                                                                        </div>
                                                    
                                                    
                                                                        <div class="form-group m-form__group row">
                                                                            <label class="col-lg-2 col-form-label">Comments:<span
                                                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                                                            <div class="col-lg-9">
                                                                                <textarea class="form-control m-input" id="macomments" name="macomments" rows="3"
                                                                                    maxlength="1500" [(ngModel)]="EnquiryObj.EnquiryDetails.Comments"
                                                                                    (keyup)="limitTextOnKeyUpDown3($event)" (keypress)="limitTextOnKeyUpDown3($event)"
                                                                                    #macomments="ngModel"
                                                                                    [ngClass]="{ 'is-invalid': (myform1.submitted && macomments.invalid) || (macomments?.touched && macomments.invalid) }"
                                                                                    required>
                                                                                                                                </textarea>
                                                                                <span> Limit {{maxappdescLimit}} characters,
                                                                                    {{remappdescLimit}} characters
                                                                                    remaining.</span>
                                                                                <div *ngIf="(myform1.submitted && macomments.invalid) || (macomments?.touched && macomments.invalid)"
                                                                                    class="invalid-feedback">
                                                                                    <div *ngIf="macomments.errors.required">
                                                                                        Comment is required
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                    
                                                                </div>
                                                    
                                                                <div class="button-row text-right mt-4" style="margin:15px;">
                                                                    <div>
                                                                        <div class="m-portlet__head-caption">
                                                                            <span *ngFor="let item of workflowActionList;let i = index;">
                                                                                <button class="btn btn-primary ml-auto js-btn-next btnspc"
                                                                                    type="{{item.workflowActionType}}"
                                                                                    (click)="OnWorkflowAction(myform1,item.workflowActionId)">
                                                                                    {{item.workflowActionName}}
                                                                                </button>
                                                                            </span>
                                                                            <button *ngIf="showRFQBtn" class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                                                                matStepperNext>Go
                                                                                to RFQ</button>
                                                                            <button class="btn btn-metal m-btn m-btn--air m-btn--custom" type="button"
                                                                                (click)="backtoList()" routerLinkActive="active">Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    
                                                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                                                    <div class="m-portlet__head">
                                                                        <div class="m-portlet__head-caption">
                                                                            <div class="m-portlet__head-title">
                                                                                <h3 class="m-portlet__head-text">
                                                                                    Engagement Activities
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                        <div _ngcontent-yjk-c387="" class="m-portlet__head-tools">
                                                                            <ul _ngcontent-yjk-c387="" class="m-portlet__nav">
                                                                                <li _ngcontent-yjk-c387="" class="m-portlet__nav-item"><a _ngcontent-yjk-c387=""
                                                                                        class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                                                                        [routerLink]="['/Add-Activity']"
                                                                                        [queryParams]="{EntityType: 'Enquiry', EnqId: Id, CustId: EnquiryObj.EnqCustomerDetails.CustomerId}">
                                                                                        Add Activity </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div class="m-portlet__body">
                                                                        <div class="table-responsive">
                                                                            <app-activity-list-by-entity [entityTypeId]="3"></app-activity-list-by-entity>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    
                                                    
                                                    
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>                                               
                                                <div class="clearfix" style="margin-top: 20px;"></div>

                                            </mat-step>


                                            <!-- done -->
                                            <mat-step *ngIf="isRFQVisible && isVisibleStep('RFQ')">                                              
                                                <ng-template matStepLabel><span data-toggle="m-tooltip"
                                                        data-placement="bottom" title="RFQ"
                                                        data-original-title="RFQ">RFQ</span></ng-template>
                                                <app-rfq (updateLoaderEvent)="updateLoader($event)"></app-rfq>

                                                <br />
                                            </mat-step>

                                            <!-- done -->
                                            <mat-step *ngIf="isQuoteGenerationVisible && isVisibleStep('QUOTATION')">
                                                <ng-template matStepLabel><span data-toggle="m-tooltip"
                                                        data-placement="bottom" title="Quote Generation"
                                                        data-original-title="Quote Generation by L1/L2/L3">Quote
                                                        Generation</span></ng-template>
                                                <app-quotation
                                                    (updateLoaderEvent)="updateLoader($event)"></app-quotation>
                                            </mat-step>

                                            <!-- done -->
                                            <mat-step
                                                *ngIf="EnquiryConfirmationVisible && isVisibleStep('ENQUIRY_CONFIRMATION')">
                                                <ng-template matStepLabel><span data-toggle="m-tooltip"
                                                        data-placement="bottom" title="Enquiry Confirmation"
                                                        data-original-title="Enquiry
                                                        Confirmation">Order
                                                        Confirmation</span></ng-template>

                                                <app-order-confirmation
                                                    (updateLoaderEvent)="updateLoader($event)"></app-order-confirmation>
                                            </mat-step>

                                            <!-- done -->
                                            <mat-step *ngIf="isSOVisible && isVisibleStep('SALES_ORDER')">
                                                <ng-template matStepLabel><span data-toggle="m-tooltip"
                                                        data-placement="bottom" title="Sales Order(SO) Generation"
                                                        data-original-title="Sales Order(SO) Generation">Sales Order(SO)
                                                        Generation</span></ng-template>
                                                <app-so-generation
                                                    (updateLoaderEvent)="updateLoader($event)"></app-so-generation>
                                            </mat-step>

                                            <!-- done -->
                                            <mat-step
                                                *ngIf="isManagementApprovalVisible && isVisibleStep('MANAGEMNT_APPROVAL')">
                                                <ng-template matStepLabel><span data-toggle="m-tooltip"
                                                        data-placement="bottom" title="Management Approval"
                                                        data-original-title="Management Approval">Management
                                                        Approval</span></ng-template>
                                                <app-management-approval (updateLoaderEvent)="updateLoader($event)">
                                                </app-management-approval>
                                            </mat-step>

                                            <!-- done -->
                                            <mat-step *ngIf="isCEOApprovalVisible && isVisibleStep('CEO_APPROVAL')">
                                                <ng-template matStepLabel><span data-toggle="m-tooltip"
                                                        data-placement="bottom" title="CEO Approval"
                                                        data-original-title="CEO Approval">CEO
                                                        Approval</span></ng-template>

                                                <app-ceo-approval
                                                    (updateLoaderEvent)="updateLoader($event)"></app-ceo-approval>
                                            </mat-step>


                                            <!-- done -->
                                            <mat-step *ngIf="isOAGenerationVisible && isVisibleStep('ORDER_ACK')">
                                                <ng-template matStepLabel><span data-toggle="m-tooltip"
                                                        data-placement="bottom" title="Order Acknowledgement"
                                                        data-original-title="Order Acknowledgement">Order
                                                        Acknowledgement</span>
                                                </ng-template>
                                                <app-oa-generation
                                                    (updateLoaderEvent)="updateLoader($event)"></app-oa-generation>
                                            </mat-step>

                                            <!-- done -->
                                            <mat-step *ngIf="isSupplychainVisible && isVisibleStep('SUPPLY_CHAIN')">
                                                <ng-template matStepLabel><span data-toggle="m-tooltip"
                                                        data-placement="bottom" title="Supply Chain"
                                                        data-original-title="Supply Chain">Supply
                                                        Chain</span>
                                                </ng-template>
                                                <app-supply-chain
                                                    (updateLoaderEvent)="updateLoader($event)"></app-supply-chain>
                                            </mat-step>

                                            <!-- done -->
                                            <mat-step *ngIf="isAccountsVisible && isVisibleStep('CONFIRM_INVOICE')">
                                                <ng-template matStepLabel><span data-toggle="m-tooltip"
                                                        data-placement="bottom" title="Confirm Invoice"
                                                        data-original-title="Confirm Invoice">Confirm
                                                        Invoice</span>
                                                </ng-template>

                                                <app-confirm-invoice
                                                    (updateLoaderEvent)="updateLoader($event)"></app-confirm-invoice>
                                            </mat-step>

                                        </mat-horizontal-stepper>

                                        <div class="table-responsive scrollit" *ngIf="EnquiryObj.Comments.length!=0">
                                            <table
                                                class="table order-list table-striped- table-bordered table-hover table-edit "
                                                id="rfqtable">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 15%;" class="text-center">
                                                            Enquiry Status
                                                        </th>
                                                        <th style="width: 12%;" class="text-center">
                                                            Pending at Role
                                                        </th>
                                                        <th style="width: 12%;" class="text-center">
                                                            Action by
                                                        </th>
                                                        <th style="width: 4%;" class="text-center">
                                                            Action by Role
                                                        </th>
                                                        <th style="width: 40%;" class="text-center">
                                                            Comment
                                                        </th>
                                                        <th style="width: 9%;" class="text-center">
                                                            Comment Date
                                                        </th>
                                                        <th style="width: 15%;" class="text-center">
                                                            Attachments
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngFor="let field of EnquiryObj.Comments; let i = index;">
                                                        <tr>
                                                            <td>
                                                                {{field.DisplayStatus}}
                                                            </td>
                                                            <td>
                                                                {{field.PendingAtRole}}
                                                            </td>
                                                            <td>
                                                                {{field.CommentsBy}}
                                                            </td>
                                                            <td>
                                                                {{field.RoleName}}
                                                            </td>
                                                            <td>
                                                                {{field.Comments}}
                                                            </td>
                                                            <td>
                                                                {{field.CommentDate | date: "dd-MMM-yyyy h:mm a"}}
                                                            </td>
                                                            <td>
                                                                <a *ngIf="!field.showAttachments && field.AttachmentsArray.length!=0"
                                                                    style="cursor: pointer;font-style: italic;color: blue;"
                                                                    (click)="toggleAttchments(i)"><u>See
                                                                        Attachments</u></a>
                                                                <a *ngIf="field.showAttachments"
                                                                    style="cursor: pointer;font-style: italic;color: blue;"
                                                                    (click)="toggleAttchments(i)"><u>Hide
                                                                        Attachments</u></a>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="field.showAttachments">
                                                            <td class="text-center" colspan="7">
                                                                <span *ngFor="let f of field.AttachmentsArray">
                                                                    <a target="_blank"
                                                                        href="{{fileDownloadPath}}{{f.FilePath}}">{{f.FileName}}</a><br />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </ng-container>

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
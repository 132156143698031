
import { Component, OnInit, ViewChild, Input, ɵclearResolutionOfComponentResourcesQueue, ElementRef, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { EmployeeGuestModel } from 'src/app/Model/EmployeeGuestModel';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import * as FileSaver from 'file-saver';
import { BasicCustomerInformationModel } from 'src/app/Model/CustomerModel';
import { EnquiryModel, CustomerDrpdnDetails, CustomerDetails, ProductDrpdnDetails, EnquiryDetailsModel, ProductDetails, Comments, removefiles, TermsAndCondition, ActivityModel, ProductModelPOValues, CustomTermsAndCondition, DynamicTermsAndCondition } from 'src/app/Model/EnquiryModel';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { NgbDate, NgbModal, NgbActiveModal, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppGlobals } from 'src/app/app.global';
import { UserRoles } from "src/app/UserRoles";
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { MatTableDataSource } from '@angular/material/table';
import { EnquiryModalComponent } from '../enquiry-modal/enquiry-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { QuotationDetailsModel, QuotationCustomerDetails } from 'src/app/Model/QuotationModel';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import {
  saveAs as importedSaveAs
} from "file-saver";
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { TechnicalDiscardComponent } from '../technical-discard/technical-discard.component';
import { CloseOppReasonComponent } from '../close-opp-reason/close-opp-reason.component';
import { EnquiryStatus, EnquiryStatusCode } from "src/app/EnquiryStatus";
import { EnquiryStatusList } from 'src/app/Model/EnquiryStatus';
import { EngagementReportServiceService } from 'src/app/Service/engagement-report-service.service';
import { EnquirySendBack } from 'src/app/Model/EnquirySendBack';
import { SendBackCommentComponent } from '../send-back-comment/send-back-comment.component';
import { forkJoin, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
declare var require: any


@Component({
  selector: 'app-so-generation',
  templateUrl: './so-generation.component.html',
  styleUrls: ['./so-generation.component.scss']
})
export class SoGenerationComponent implements OnInit {

  @ViewChild('myform3') myform3: NgForm;
  @ViewChild('myform5') myform5: NgForm;
  @ViewChild('myInput') myInputVariable: ElementRef;
  @ViewChild('editpovalue', { static: false }) editpovalue: ElementRef;
  standardTC: any[] = [];
  customTCValues: any[] = [];
  CustomerAddressList: any;

  userInfo: EmployeeGuestModel = new EmployeeGuestModel();
  CustomerList: BasicCustomerInformationModel[] = [];
  EnquiryObj: EnquiryDetailsModel = new EnquiryDetailsModel();
  newRFQDetails: any = {};
  productListdropdownData = [];
  customerDrpdnDetails: CustomerDrpdnDetails = new CustomerDrpdnDetails();
  productDrpdnDetails: ProductDrpdnDetails = new ProductDrpdnDetails();
  customerData: CustomerDetails;
  activityDetails: ActivityModel[] = Array<ActivityModel>();
  CustomerDetailsObj: QuotationCustomerDetails = new QuotationCustomerDetails();
  QuotationData: QuotationDetailsModel = new QuotationDetailsModel();
  allowedFileType: string[] = [
    'image/bmp',
    'image/jpeg',
    'image/png',
    'application/pdf',
    'text/plain',
    'text/csv',
    'application/xml',
    'text/xml',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/zip',
    'application/x-zip-compressed',
  ];

  showEditRFQForInitiator: boolean;
  UserRole: any;
  isTechnicalDiscardVisible: boolean = false;
  isEnquiryFormVisible: boolean = false;
  isRFQVisible: boolean = false;
  isQuoteGenerationVisible: boolean = false;
  isManagementApprovalVisible: boolean = false;
  isSOVisible: boolean = false;
  isOrderAcknowledgementVisible: boolean = false;
  EnquiryConfirmationVisible: boolean = false;
  isSupplychainVisible: boolean = false;
  isAccountsVisible: boolean = false;
  iseditable: boolean = false;
  isOAGenerationVisible: boolean = false;
  isBOMAttachment: boolean;
  isInvoiceAttachment: boolean;
  isPOAttachment: boolean;
  IsRFQDataValid: boolean = true;
  selectedIndexForStatus: any = 1;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  DiscountFromrange: any;
  Discounttorange: any;
  calculatedTotalPrice: any;
  IsIncludedAllSOChargesResult: boolean = false;
  totalCalculatedPriceAfterIncludingAllCharges: number = 0;
  totalsum: number = 0;
  totalCalculatedPriceOfAllProductModel: number = 0;
  tempTotalCalculatedPriceOfAllProductModel : number = 0;

  @Input()
  showLoader: boolean = false;
  charCode: number;
  enquiryStatus = EnquiryStatus;
  AppRoles = UserRoles;
  enquirySource: SingleFieldModel[] = [];
  TnCStandardFromDropDown: any[] = [];
  TnCCustomizedFromDropDown: any[] = [];
  TnCCustomizedDropDownForEditRfq1: any[] = [];
  TnCCustomizedDropDownForEditRfq2: any[] = [];
  TndCFromDropDown: any[] = [];
  newCustomTc: any;
  product: any = {};
  isEnquirySubmit: boolean = false;
  charCode1: any;
  pageNumber: number = 1;
  specialPkgDiscount: number;
  poPrice: number = 0;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  dataSource: MatTableDataSource<Comments>;
  enquiryStatusCode = EnquiryStatusCode;

  @ViewChild('stepper') stepper: MatStepper;
  AppGlobals = AppGlobals;
  fileDownloadPath = this.AppGlobals.baseFileDownloadUrl;
  QuotationDownloadPath = this.AppGlobals.QuotationFileDownloadUrl;
  isEnquiryLoaded: boolean;
  role: string;
  isStandardCondition: boolean;
  closeResult: string;
  isCEOApprovalVisible: boolean;
  demoObject: any;
  demoDynamicObject: any;
  demoCTCObject: any;
  WinLossDropdownData: any[] = [];
  WinLossDropdownDataForStatus: any;
  isPoValueDisable: boolean;
  isCustomerCommentVisible: any;
  isOAGenerated: boolean;
  isAcceptClick: boolean;
  dataPassed: any;
  dropdownData: any;
  showQuotationLink: boolean;
  userid: string;
  isprocessing: boolean = true;
  rfqattpresent: boolean;
  QuotationDataForPO: any[] = [];
  chosenItem: string = "1";
  quoterevcomments: any;
  QGRFQEditable: boolean = false;
  IsActivityDataValid: boolean;
  today: Date;
  fc = new FormControl();
  minDate: NgbDate;
  showEditRFQ: boolean;
  isTantativeDateEditable: boolean;
  tempRFQ: ProductDetails[];
  IsPODataValid: boolean;
  isEditactivity: boolean = true;
  PreviousStatus: number = 0;
  tempfile: any;
  ngbDateParserFormatter: any;
  UpdatePO: boolean = false;
  isPOAttacmentDelete: boolean = false;
  RevisedQuotationFileNameList: string[] = [];
  emailPattern: string = "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  StartDate: NgbDate;
  EndDate: NgbDate;
  RoleWiseScreenDisplay: any;
  previousUrl: string = null;
  IndustrySegment: any[] = [];
  SubSegment: any[] = [];
  Application: any[] = [];

  FinalIndustrySegment: any[] = [];
  FinalSubSegment: any[] = [];
  FinalApplication: any[] = [];
  workflowActionList: any[] = [];
  enquiryStatusList: EnquiryStatusList[] = [];
  workflowId: number;
  roleOfAdmin : any;

  @Output() updateLoaderEvent = new EventEmitter<boolean>();
  UserRolesData:any[]=[];
  WinLossDataForStatus: any;
  isSOEditable: boolean=false;

  constructor(private toastr: ToastrService, private commonService: CommonService, public dialog: MatDialog,
    private _location: Location,
    private config: NgbDatepickerConfig,
    private modalService: NgbModal, private activeModel: NgbActiveModal,
    private workflowService: EngagementReportServiceService,
    private router: Router, private route: ActivatedRoute, private titleService: Title, private cd: ChangeDetectorRef) {
    this.today = new Date();
    this.minDate = new NgbDate(this.today.getFullYear(), this.today.getMonth() + 1, this.today.getDate());
    this.StartDate = new NgbDate(2018, 1, 1);
    this.EndDate = new NgbDate(this.today.getFullYear() + 1, 12, 31);


  }
  ngAfterViewInit() {
    this.updateLoaderEvent.emit(true);
    setTimeout(() => {
      this.isprocessing = false;
      this.chosenItem = "1";
      this.updateLoaderEvent.emit(false);
    }, 2000);
  
  }
  ngOnInit() {
    this.commonService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });

    let tempRoleWiseScreenDisplay = localStorage.getItem("WorkFlowRoleWiseScreenAccess");
    this.RoleWiseScreenDisplay = tempRoleWiseScreenDisplay.split(",");
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.commonService.RefreshHeaderFun();
    this.EnquiryObj.EnquiryDetails.EnquiryId = this.route.snapshot.params["id"];
    this.EnquiryObj.EnquiryDetails.EstimatedProductValue = null;
    this.EnquiryObj.EnquiryDetails.InvoiceAttachment = undefined;
    this.getRFQProduct();
    this.getEnquirySourceDetails();
    this.getCustomerList();
    //this.getEnquiryDetailsById();
    this.isEnquiryLoaded = false;
    this.getEnquiryStatus();    
    this.getUserRoles();
    this.getSendbackWorkfow();
    this.UserRole = localStorage.getItem("UserRole");
    this.isTechnicalDiscardVisible = AppGlobals.QuoteGenearation_Enquiry_TechnicalDiscardArray.filter(x => x.role
      == this.UserRole)[0]?.isEditable;

    this.roleOfAdmin = UserRoles.Admin;
    forkJoin([
      this.getAllWorkFlow()
    ]).pipe(tap(res => {           
    this.getEnquiryDetailsById();   
    })).subscribe(x=>{
     
    });
  }
  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }


  toggleAttchments(i) {

    this.EnquiryObj.Comments[i].showAttachments = !this.EnquiryObj.Comments[i].showAttachments;
  }
  deletePOAttachment(flag: any) {
    // this.EnquiryObj.EnquiryDetails.POAttchments = [];
    // this.isPOAttachment = false;
    if (flag == 1) {
      this.EnquiryObj.RemoveFiles.push(this.EnquiryObj.EnquiryDetails.PO_filepath);
      this.isPOAttacmentDelete = true;
    }
    this.isBOMAttachment = false;
    this.EnquiryObj.EnquiryDetails.POAttchments = [];
    this.EnquiryObj.EnquiryDetails.PO_filename = null;
    this.EnquiryObj.EnquiryDetails.PO_filepath = null;
    this.isPOAttachment = false;
  }
  checkActivityValidations() {
    this.IsActivityDataValid = true;
    this.activityDetails.forEach(element => {
      if (element.Activity == undefined || element.Activity == null || element.Activity == "") {
        element.ActivityValidation = true;
        this.IsActivityDataValid = false;
      }
      if (element.ActivityDate == undefined || element.ActivityDate == null || element.ActivityDate == "") {
        element.ActivityDateValidation = true;
        this.IsActivityDataValid = false;
      }
    });
  }
  editActivity() {
    this.isEditactivity = true;
  }
  cancelEditActivity() {
    this.isEditactivity = false;
  }
  SaveActivityDetails() {
    this.checkActivityValidations();
    if (!this.IsActivityDataValid) {
      return;
    }
    this.updateLoaderEvent.emit(true);
    this.activityDetails.forEach(element => {
      if (element.ActivityDate != undefined) {
        element.ActivityDateISO = new Date(this.convertDate(new NgbDate(element.ActivityDate.year, element.ActivityDate.month, element.ActivityDate.day))).toDateString();
      }
      if (element.ActivityDate != undefined) {
        element.ActivityDate = new Date(this.convertDate(new NgbDate(element.ActivityDate.year, element.ActivityDate.month, element.ActivityDate.day)));
      }
      element.EnquiryId = this.EnquiryObj.EnquiryDetails.EnquiryId;
    });

    let activityDetails = JSON.stringify(this.activityDetails);
    const frmData = new FormData();
    frmData.append("ActivityObj", activityDetails);
    var i = 0;
    this.activityDetails.forEach(element => {
      if (element.Attachment != undefined) {
        frmData.append("Attachment[" + i + "]", this.activityDetails[i].Attachment[0]);
      }
      i++;
    });

    this.commonService.PostData("Enquiry", "AddActivity", frmData, null).subscribe((data: any) => {
      this.activityDetails.forEach(element => {
        element.ActivityDate = new NgbDate(new Date(element.ActivityDate).getFullYear(), new Date(element.ActivityDate).getMonth() + 1, new Date(element.ActivityDate).getDate());
      });
      //this.isEditactivity = false;
      this.toastr.success("Activity details added successfully!");
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.toastr.error("Failed to add activity details!");
      this.updateLoaderEvent.emit(false);
    });

  }

  getQuotationData() {
    this.updateLoaderEvent.emit(true);
    let queryString = "";
    queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
    this.commonService.GetData('Enquiry', 'GetQuotationByEnquiryId', queryString).subscribe((data: any) => {
      this.QuotationDataForPO = data.QuotationObj;
      this.QuotationDataForPO.forEach(element => {
        var obj = new ProductModelPOValues();
        obj.ProductId = element.ProductId;
        obj.ProductModelName = element.ProductModelName;
        if (element.Variant1Name != undefined) obj.ProductModelName = obj.ProductModelName + ' (' + element.Variant1Name;
        if (element.Variant2Name != undefined) obj.ProductModelName = obj.ProductModelName + ', ' + element.Variant2Name;
        if (element.Variant3Name != undefined) obj.ProductModelName = obj.ProductModelName + ', ' + element.Variant3Name;
        if (element.Variant4Name != undefined) obj.ProductModelName = obj.ProductModelName + ', ' + element.Variant4Name;
        obj.ProductModelName = obj.ProductModelName + ')'
        obj.QUotationValue = element.FinalAmount;
        obj.ProductModelPOValue = "";
        this.EnquiryObj.POValues.push(obj);
      });
    });
  }

  getEnquiryDetailsById() {
    this.updateLoaderEvent.emit(true);
    let queryString = "";
    queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
    let tracker=this.commonService.GetData('Enquiry', 'GetById', queryString);
    tracker.subscribe((data: any) => {
      this.EnquiryObj.EnquiryDetails = data.EnquiryDetails;
      if (this.EnquiryObj.EnquiryDetails.QuotationRevisionNo != null && this.EnquiryObj.EnquiryDetails.QuotationRevisionNo > 0) {
        this.getRevisedQuotationFileNamelist(this.EnquiryObj.EnquiryDetails.QuotationNumber, this.EnquiryObj.EnquiryDetails.QuotationRevisionNo);
      }

      this.EnquiryObj.EnquiryDetails.EnquiryDate = new NgbDate(new Date(this.EnquiryObj.EnquiryDetails.EnquiryDate).getFullYear(), new Date(this.EnquiryObj.EnquiryDetails.EnquiryDate).getMonth() + 1, new Date(this.EnquiryObj.EnquiryDetails.EnquiryDate).getDate());
      if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != null) {
        this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate = new NgbDate(new Date(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate).getFullYear(), new Date(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate).getMonth() + 1, new Date(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate).getDate());
      }
      if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != null) {
        this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate = new NgbDate(new Date(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate).getFullYear(), new Date(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate).getMonth() + 1, new Date(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate).getDate());
      }
      this.EnquiryObj.EnqCustomerDetails = data.CustomerDetails;
      this.EnquiryObj.RFQObject = data.RFQObject;
      this.EnquiryObj.CustomerServiceComments_Quotation = data.CustomerServiceComments_Quotation;
      this.EnquiryObj.CustomerServiceComments_Order = data.CustomerServiceComments_Order;
      this.EnquiryObj.SalesDirectorComments_Quotation = data.SalesDirectorComments_Quotation;

      this.EnquiryObj.SalesDirectorComments_Order = data.SalesDirectorComments_Order;
      this.EnquiryObj.Comments = data.Comments == undefined ? [] : data.Comments;
      this.EnquiryObj.POValues = data.POValues;

      this.EnquiryObj.CustomerServiceDetails = data.CustomerServiceDetails;
      this.WinLossDropdownData = data.ReasonEnquiryWinLossObj;
      this.WinLossDropdownDataForStatus = this.WinLossDropdownData.filter(x => x.Value == this.EnquiryObj.EnquiryDetails.EnquiryConfirmStatus);
      this.EnquiryObj.DynamicTCDetailObj = data.DynamicTCDetailObj;

      if (data.EnquiryDetails.RFQTermsAndCondition == "Standard" || data.EnquiryDetails.RFQTermsAndCondition == "") {

        if (data.TermsAndConditions.length == 0) {
          this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition = "Standard";
          this.EnquiryObj.TermsAndConditions = this.TnCStandardFromDropDown;
        } else {
          this.EnquiryObj.TermsAndConditions = data.TermsAndConditions;

        }
        this.isStandardCondition = true;
      } else if (data.EnquiryDetails.RFQTermsAndCondition == "Customized") {

        if (data.CustomTermsAndConditions.length == 0) {
          this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition = "Customized";
          this.EnquiryObj.CustomTermsAndConditions = this.TnCCustomizedFromDropDown;
          this.TnCCustomizedDropDownForEditRfq1 = this.TnCCustomizedFromDropDown;
        } else {
          this.EnquiryObj.CustomTermsAndConditions = data.CustomTermsAndConditions;
          this.TnCCustomizedDropDownForEditRfq2 = data.CustomTermsAndConditions;
        }

        this.isStandardCondition = false;
      }

      this.activityDetails = data.ActivityDetails;
      this.activityDetails.forEach(element => {
        element.ActivityDate = new NgbDate(new Date(element.ActivityDate).getFullYear(), new Date(element.ActivityDate).getMonth() + 1, new Date(element.ActivityDate).getDate());

        //element.ActivityDateISO = new NgbDate(new Date(element.ActivityDate).getFullYear(), new Date(element.ActivityDate).getMonth() + 1, new Date(element.ActivityDate).getDate());
      });

      //TO POPULATE A SINGLE ROW ON PAGE INIT
      if (this.EnquiryObj.RFQObject.length == 0) {
        var obj = new ProductDetails();
        this.EnquiryObj.RFQObject.push(obj);
      }

      if (this.EnquiryObj.DynamicTCDetailObj.length == 0) {
        var object = new DynamicTermsAndCondition();
        this.EnquiryObj.DynamicTCDetailObj.push(object);
      }
      //TO POPULATE A SINGLE ROW ON PAGE INIT
      if (this.activityDetails.length == 0) {
        var obj1 = new ActivityModel();
        obj1.ActivityDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
        obj1.uniqueid = this.makeRandom();
        this.activityDetails.push(obj1);
      }

      this.role = localStorage.getItem('UserRole');
     // this.ScreenAccessSpecifier(localStorage.getItem('UserRole'), this.EnquiryObj.EnquiryDetails.Status);
      //SET DISABLE PROPERTIES
      if(this.workflowActionList.length>0 )
      {
        this.isSOEditable=true;
      }
      else{
        this.isSOEditable=false;
      }

      if (this.EnquiryObj.EnquiryDetails.segment != undefined) {
        this.SubSegment = this.FinalSubSegment.filter(x => x.SegmentId == this.EnquiryObj.EnquiryDetails.segment);
        this.Application = this.FinalApplication.filter(x => x.SubSegmentId == this.EnquiryObj.EnquiryDetails.subSegment);
      }
      if (this.EnquiryObj.EnquiryDetails.segment == null || this.EnquiryObj.EnquiryDetails.subSegment == null || this.EnquiryObj.EnquiryDetails.application == null) {
        this.EnquiryObj.EnquiryDetails.segment = undefined;
        this.EnquiryObj.EnquiryDetails.subSegment = undefined;
        this.EnquiryObj.EnquiryDetails.application = undefined;
      }

      this.EnquiryObj.EnquiryDetails.IsFetchFromKYC = data.EnquiryDetails.IsFetchFromKYC;
      this.getCustomerAddresses(this.EnquiryObj.EnqCustomerDetails.CustomerId);

      this.totalCalculatedPriceOfAllProductModel = parseFloat(this.EnquiryObj.EnquiryDetails.POValue);

    }, error => {
      this.updateLoaderEvent.emit(false);
    });

    return tracker;
  }

  getRevisedQuotationFileNamelist(QuotationNumber: string, QuotationRevision: number) {
    let QTNNumber = QuotationNumber.replace("QTN/", "");
    QTNNumber = QTNNumber.split("/").join("_");
    let filename = "Quotation_" + QTNNumber;
    let pdffilename = filename.split('_R')[0];
    for (let i = 0; i < QuotationRevision; i++) {
      if (i == 0) {
        this.RevisedQuotationFileNameList.push(pdffilename + ".pdf")
      }
      else {
        this.RevisedQuotationFileNameList.push(pdffilename + "_R" + i + ".pdf")
      }
    }

  }
  //   replaceAll(str, term, replacement) {
  //     return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
  // }
  makeRandom() {
    let text = "";
    var possible: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~";
    const lengthOfCode = 40;
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  getRFQProduct() {
    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("Enquiry", "GetEnquiryRFQDropdownData", null).subscribe((data: any) => {
      this.product = data;
      this.productDrpdnDetails.MDSOptions = data.MDS;
      this.productDrpdnDetails.SalesPrice = data.Salesprice;
      this.productDrpdnDetails.EstimatedProductValue = data.EstimatedProductValue;
      this.IndustrySegment = data.segment;
      this.SubSegment = [];
      this.Application = [];
      this.FinalIndustrySegment = data.segment;
      this.FinalSubSegment = data.subSegment;
      this.FinalApplication = data.application;
      data.TermsAndConditions.forEach(element => {
        var obj = new TermsAndCondition();
        obj.TnCId = element.Id;
        obj.TnCName = element.Name;
        obj.TnCValue = element.Value;
        this.TnCStandardFromDropDown.push(obj);
      });

      this.demoObject = data.TermsAndConditions;

      this.demoCTCObject = data.CustomTermsAndCondition;

      for (let i = 0; i < data.CustomTermsAndCondition.length; i++) {
        var obj1 = new CustomTermsAndCondition();
        obj1.StandardTC = data.CustomTermsAndCondition[i].StandardTC.Id;
        obj1.StandardTCName = data.CustomTermsAndCondition[i].StandardTC.Name;
        // this.standardTC.push(data.CustomTermsAndCondition[i].StandardTC);

        for (let j = 0; j < data.CustomTermsAndCondition[i].CustomValues.length; j++) {
          obj1.CustomValues.push(data.CustomTermsAndCondition[i].CustomValues[j]);
        }
        this.TnCCustomizedFromDropDown.push(obj1);
      }

      this.demoCTCObject = data.CustomTermsAndCondition;

      //this.updateLoaderEvent.emit(false);
    });
  }

  custtermscond: any = [];
  OnCustomValueChange(index, id) {
    let CustTcObj: CustomTermsAndCondition = new CustomTermsAndCondition();
    CustTcObj.StandardTC = this.EnquiryObj.CustomTermsAndConditions[index].StandardTC;
    CustTcObj.StandardTCName = this.EnquiryObj.CustomTermsAndConditions[index].StandardTCName;
    CustTcObj.CustValue = this.EnquiryObj.CustomTermsAndConditions[index].CustomValues.find(x => x.Id == id).Name;
    CustTcObj.CustTCId = id;

    this.custtermscond.push(CustTcObj)
   // console.log(this.custtermscond);
  }


  OnCustomValueChange1(index, id) {
    let CustTcObj1: CustomTermsAndCondition = new CustomTermsAndCondition();
    CustTcObj1.StandardTC = this.EnquiryObj.CustomTermsAndConditions[index].StandardTC;
    CustTcObj1.StandardTCName = this.EnquiryObj.CustomTermsAndConditions[index].StandardTCName;
    CustTcObj1.CustValue = this.EnquiryObj.CustomTermsAndConditions[index].CustomValues.find(x => x.Id == id).Name;
    CustTcObj1.CustTCId = id;

    let newCustomiseArr = this.EnquiryObj.CustomTermsAndConditions
    this.newCustomTc = newCustomiseArr.map((item, index) => {

      return {
        ...item,
        CustTCId: item.StandardTC == CustTcObj1.StandardTC ? Number(CustTcObj1.CustTCId) : Number(item.CustTCId),
        CustValue: item.StandardTC == CustTcObj1.StandardTC ? CustTcObj1.CustValue : item.CustValue
      }

    });
    this.EnquiryObj.CustomTermsAndConditions = this.newCustomTc;
  }


  getProductModel(Id, ind) {
    this.EnquiryObj.RFQObject[ind].ProductLineValidation = false;
    this.product.Product.forEach((element, index) => {
      if (element.ProductLine.Id == Id) {
        this.productDrpdnDetails.ProductModel = element.ProductModels;
        this.newRFQDetails.ProductModelList = element.ProductModels;
        this.EnquiryObj.RFQObject[ind].isProductLineChangeClick = true;
        this.EnquiryObj.RFQObject[ind].ProductModelList = this.productDrpdnDetails.ProductModel;
      }
    });
  }
  OnProductModelChange(index) {
    this.EnquiryObj.RFQObject[index].ProductModelValidation = false;
  }
  OnQuantityChange(index) {
    if (this.EnquiryObj.RFQObject[index].Quantity < 1) {
      //alert('Quantity cannot be 0 or negative.');
      this.toastr.info('Quantity cannot be 0 or negative.');
      this.EnquiryObj.RFQObject[index].Quantity = null;
      return;
    } else if (this.EnquiryObj.RFQObject[index].Quantity % 1 != 0) {
      // alert('Please enter the valid Quantity');
      this.toastr.info('Please enter the valid Quantity');

      this.EnquiryObj.RFQObject[index].Quantity = null;
      return;
    }
    this.EnquiryObj.RFQObject[index].QuantityValidation = false;
  }


  deleteAttachDocument(index, attachemntType) {
    switch (attachemntType) {
      case "MDS":
        this.EnquiryObj.RFQObject[index].MDSAttachment = [];
        this.EnquiryObj.RFQObject[index].isMDSAttachment = false;
        break;
      case "ITC":
        this.EnquiryObj.RFQObject[index].ITCAttachment = [];
        this.EnquiryObj.RFQObject[index].isITCAttachment = false;
        break;
      case "Customer":
        this.EnquiryObj.RFQObject[index].CustomerAttachment = [];
        this.EnquiryObj.RFQObject[index].isCustomerAttachment = false;
        break;
      case "Weiss":
        this.EnquiryObj.RFQObject[index].WeissAttachment = [];
        this.EnquiryObj.RFQObject[index].isWeissAttachment = false;
        break;
    }

  }

  deleteActivityAttachments(index) {
    this.activityDetails[index].Attachment = [];
    this.activityDetails[index].isActivityAttachment = false;
  }

  deleteActivityAttachmentsFromDB(index) {
    this.activityDetails[index].fileName = null;
    this.activityDetails[index].filePath = null;
    this.EnquiryObj.RemoveFiles.push(this.activityDetails[index].filePath);
  }


  deleteAttachDocumentFromDB(index, attachemntType) {
    switch (attachemntType) {
      case "MDS":
        var fileObj = new removefiles();
        fileObj.ProductId = this.EnquiryObj.RFQObject[index].ProductId;
        fileObj.filetype = "MDS";
        this.EnquiryObj.RemoveFiles.push(this.EnquiryObj.RFQObject[index].MDS_filepath);
        this.EnquiryObj.RFQObject[index].MDS_filename = null;
        this.EnquiryObj.RFQObject[index].MDS_filepath = null;
        break;
      case "ITC":
        var fileObj = new removefiles();
        fileObj.ProductId = this.EnquiryObj.RFQObject[index].ProductId;
        fileObj.filetype = "ITC";
        this.EnquiryObj.RemoveFiles.push(this.EnquiryObj.RFQObject[index].ITC_filepath);
        this.EnquiryObj.RFQObject[index].deleted_ITC_filename = this.EnquiryObj.RFQObject[index].ITC_filename;
        this.EnquiryObj.RFQObject[index].deleted_ITC_filepath = this.EnquiryObj.RFQObject[index].ITC_filepath;
        this.EnquiryObj.RFQObject[index].ITC_filename = null;
        this.EnquiryObj.RFQObject[index].ITC_filepath = null;

        break;
      case "Customer":
        var fileObj = new removefiles();
        fileObj.ProductId = this.EnquiryObj.RFQObject[index].ProductId;
        fileObj.filetype = "Customer";
        this.EnquiryObj.RemoveFiles.push(this.EnquiryObj.RFQObject[index].Customer_filepath);
        this.EnquiryObj.RFQObject[index].Customer_filename = null;
        this.EnquiryObj.RFQObject[index].Customer_filepath = null;
        break;
      case "Weiss":
        var fileObj = new removefiles();
        fileObj.ProductId = this.EnquiryObj.RFQObject[index].ProductId;
        fileObj.filetype = "Weiss";
        this.EnquiryObj.RemoveFiles.push(this.EnquiryObj.RFQObject[index].Weiss_filepath);
        this.EnquiryObj.RFQObject[index].Weiss_filename = null;
        this.EnquiryObj.RFQObject[index].Weiss_filepath = null;
        break;
    }

  }

  deleteActivityDetails(index) {
    if (index != null) {
      this.activityDetails.splice(index, 1);
    }
  }
  addActivityDetails(index) {
    if (!(this.activityDetails[index].Activity == undefined || this.activityDetails[index].Activity == null || this.activityDetails[index].Activity == "")
      &&
      !(this.activityDetails[index].ActivityDate == undefined || this.activityDetails[index].ActivityDate == null || this.activityDetails[index].ActivityDate == "")) {
      var obj = new ActivityModel();
      obj.ActivityDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
      obj.uniqueid = this.makeRandom();
      obj.ISNewActivityAdded = true;
      this.activityDetails.push(obj);
    }
  }

  getCustomerList() {
    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("Customer", "GetAll", null).subscribe((data: any) => {
      this.CustomerList = data;

      this.updateLoaderEvent.emit(false);
    });
  }

  getEnquirySourceDetails() {
    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("Enquiry", "GetEnquiryDropdownData", null).subscribe((data: any) => {
      this.enquirySource = data.EnquirySource;
      this.updateLoaderEvent.emit(false);
    });
  }

  getProductDetails() {
    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("Product", "GetRFQProductDetails", null).subscribe((data: any) => {
      this.CustomerList = data;
      //this.updateLoaderEvent.emit(false);
    });
  }


  AvoidSpace(event: any) {
    if ((event.which === 46 || event.which === 32) && !event.target.value.length) {
      event.preventDefault();
    }
  }

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  isintNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 46 || this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  openCSComments() {
    this.isCustomerCommentVisible = !this.isCustomerCommentVisible;
  }

  backtoList() {
    // this._location.back();
    if (this.previousUrl == null || this.previousUrl == "" || this.previousUrl == undefined) {
      this.router.navigate(['/Enquiry']);
    }
    else if (this.previousUrl.includes("PendingEnquiry")) {
      this.router.navigate(['/PendingEnquiry']);
    }
    else {
      this.router.navigate(['/Enquiry']);
    }

  }


  editFileDetails(event, index) {
    let file = event.target.files[0];
    let isValidFile = true;
    if (((file.size) / 1000000) <= 5) {
      isValidFile = this.allowedFileType.includes(file.type);
      if (!isValidFile) {
        this.toastr.warning("Invalid file attached!");
        switch (event.target.name) {
          case "mdsfile":
            this.EnquiryObj.RFQObject[index].MDSAttachment = [];
            this.EnquiryObj.RFQObject[index].isMDSAttachment = false;
            break;
          case "itcfile":
            this.EnquiryObj.RFQObject[index].ITCAttachment = [];
            this.EnquiryObj.RFQObject[index].isITCAttachment = false;
            break;
          case "customerfile":
            this.EnquiryObj.RFQObject[index].CustomerAttachment = [];
            this.EnquiryObj.RFQObject[index].isCustomerAttachment = false;
            break;
          case "weissfile":
            this.EnquiryObj.RFQObject[index].WeissAttachment = [];
            this.EnquiryObj.RFQObject[index].isWeissAttachment = false;
            break;
          case "bomfile":
            this.EnquiryObj.EnquiryDetails.BOMAttachment = [];
            this.isBOMAttachment = false;
            break;
          case "ecfile":
            this.EnquiryObj.EnquiryDetails.POAttchments = [];
            break;
          case "accfile":
            this.EnquiryObj.EnquiryDetails.InvoiceAttachment = [];
            this.isInvoiceAttachment = false;
            break;
          case "activityfile":
            this.activityDetails[index].Attachment = [];
            this.activityDetails[index].isActivityAttachment = false;
            break;
        }
        event.target.value = null;
        isValidFile = false;
      } else if (file.name.length > 50) {
        this.toastr.warning("The file name with more than 50 characters are not allowed.");
        switch (event.target.name) {
          case "mdsfile":
            this.EnquiryObj.RFQObject[index].MDSAttachment = [];
            this.EnquiryObj.RFQObject[index].isMDSAttachment = false;
            break;
          case "itcfile":
            this.EnquiryObj.RFQObject[index].ITCAttachment = [];
            this.EnquiryObj.RFQObject[index].isITCAttachment = false;
            break;
          case "customerfile":
            this.EnquiryObj.RFQObject[index].CustomerAttachment = [];
            this.EnquiryObj.RFQObject[index].isCustomerAttachment = false;
            break;
          case "weissfile":
            this.EnquiryObj.RFQObject[index].WeissAttachment = [];
            this.EnquiryObj.RFQObject[index].isWeissAttachment = false;
            break;
          case "bomfile":
            this.EnquiryObj.EnquiryDetails.BOMAttachment = [];
            this.isBOMAttachment = false;
            break;
          case "ecfile":
            this.EnquiryObj.EnquiryDetails.POAttchments = [];
            break;
          case "accfile":
            this.EnquiryObj.EnquiryDetails.InvoiceAttachment = [];
            this.isInvoiceAttachment = false;
            break;
          case "activityfile":
            this.activityDetails[index].Attachment = [];
            this.activityDetails[index].isActivityAttachment = false;
            break;
        }
        event.target.value = null;
        isValidFile = false;
      }
    }
    else {
      this.toastr.warning("File size must be less than or equal to 5MB!");
      switch (event.target.name) {
        case "mdsfile":
          this.EnquiryObj.RFQObject[index].MDSAttachment = [];
          this.EnquiryObj.RFQObject[index].isMDSAttachment = false;
          break;
        case "itcfile":
          this.EnquiryObj.RFQObject[index].ITCAttachment = [];
          this.EnquiryObj.RFQObject[index].isITCAttachment = false;
          break;
        case "customerfile":
          this.EnquiryObj.RFQObject[index].CustomerAttachment = [];
          this.EnquiryObj.RFQObject[index].isCustomerAttachment = false;
          break;
        case "weissfile":
          this.EnquiryObj.RFQObject[index].WeissAttachment = [];
          this.EnquiryObj.RFQObject[index].isWeissAttachment = false;
          break;
        case "bomfile":
          this.EnquiryObj.EnquiryDetails.BOMAttachment = [];
          this.isBOMAttachment = false;
          break;
        case "ecfile":
          this.EnquiryObj.EnquiryDetails.POAttchments = [];
          break;
        case "accfile":
          this.EnquiryObj.EnquiryDetails.InvoiceAttachment = [];
          this.isInvoiceAttachment = false;
          break;
        case "activityfile":
          this.activityDetails[index].Attachment = [];
          this.activityDetails[index].isActivityAttachment = false;
          break;
      }
      event.target.value = null;
      isValidFile = false;
    }
    if (isValidFile) {
      //CHECK IF THE FILE NAME IS NOT TOO BIG

      switch (event.target.name) {
        case "mdsfile":
          this.EnquiryObj.RFQObject[index].MDSAttachment = [];
          this.EnquiryObj.RFQObject[index].MDSAttachment.push(file);
          this.EnquiryObj.RFQObject[index].isMDSAttachment = true;
          break;
        case "itcfile":
          if (!(file.type == 'application/xml' || file.type == 'text/xml')) {
            this.toastr.warning("Only xml file supported!");
            this.EnquiryObj.RFQObject[index].ITCAttachment = [];
            this.EnquiryObj.RFQObject[index].isITCAttachment = false;
            event.target.value = null;
            isValidFile = false;
          }
          else {
            // const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            //   maxWidth: "400px",
            //   data: {
            //     title: "Are you sure?",
            //     message: 'Please make sure that the entered Product and ITC Product are being matched. Do you want to continue?'
            //   }
            // });
            // dialogRef.afterClosed().subscribe(dialogResult => {
            //   if (dialogResult) {
            this.EnquiryObj.RFQObject[index].ITCAttachment = [];
            this.EnquiryObj.RFQObject[index].ITCAttachment.push(file);
            this.EnquiryObj.RFQObject[index].isITCAttachment = true;
            //    }
            // });

          }
          break;
        case "customerfile":
          this.EnquiryObj.RFQObject[index].CustomerAttachment = [];
          this.EnquiryObj.RFQObject[index].CustomerAttachment.push(file);
          this.EnquiryObj.RFQObject[index].isCustomerAttachment = true;
          break;
        case "weissfile":
          this.EnquiryObj.RFQObject[index].WeissAttachment = [];
          this.EnquiryObj.RFQObject[index].WeissAttachment.push(file);
          this.EnquiryObj.RFQObject[index].isWeissAttachment = true;
          break;
        case "bomfile":
          this.EnquiryObj.EnquiryDetails.BOMAttachment = [];
          this.EnquiryObj.EnquiryDetails.BOMAttachment.push(file);
          this.isBOMAttachment = true;
          break;
        case "ecfile":
          this.EnquiryObj.EnquiryDetails.POAttchments = [];
          this.EnquiryObj.EnquiryDetails.POAttchments.push(file);
          this.isPOAttachment = true;
          break;
        case "accfile":
          this.EnquiryObj.EnquiryDetails.InvoiceAttachment = [];
          this.EnquiryObj.EnquiryDetails.InvoiceAttachment.push(file);
          this.isInvoiceAttachment = true;
          break;
        case "activityfile":
          this.activityDetails[index].Attachment = [];
          this.activityDetails[index].Attachment.push(file);
          this.activityDetails[index].isActivityAttachment = true;
          break;
      }
    }
  }

  getMultipleFileDetails(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];
      let isValidFile = true;
      if (((file.size) / 1000000) <= 5) {

        isValidFile = this.allowedFileType.includes(file.type);
        if (!isValidFile) {
          this.toastr.warning("Invalid file attached!");
          //event.target.value = null;
          isValidFile = false;
        } else if (file.name.length > 50) {
          this.toastr.warning("The file name with more than 50 characters are not allowed.");
          isValidFile = false;
        }
      }
      else {
        this.toastr.warning("File size must be less than or equal to 5MB!");
        //event.target.value = null;
        isValidFile = false;
      }
      if (isValidFile) {
        switch (event.target.name) {
          case "quotefiles":
            if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments == undefined || this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length == 0) {
              this.EnquiryObj.EnquiryDetails.QuoteGenAttachments = [];
            }
            this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.push(file);
            break;
          case "mafile":
            if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments == undefined || this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length == 0) {
              this.EnquiryObj.EnquiryDetails.QuoteGenAttachments = [];
            }
            this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.push(file);
            break;
          case "ceofile":
            if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments == undefined || this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length == 0) {
              this.EnquiryObj.EnquiryDetails.QuoteGenAttachments = [];
            }
            this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.push(file);
            break;
          case "maofile":
            if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments == undefined || this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length == 0) {
              this.EnquiryObj.EnquiryDetails.QuoteGenAttachments = [];
            }
            this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.push(file);
            break;
        }
      }
    }
  }

  removeQuoteGenAttachments(index) {
    this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.splice(index, 1);
    this.myInputVariable.nativeElement.value = "";

  }
  removeQuoteGenAttachmentsDB(index) {
    this.EnquiryObj.RemoveFiles.push(this.EnquiryObj.EnquiryDetails.QuoteAttachmentsArray[index].FilePath);
    this.EnquiryObj.EnquiryDetails.QuoteAttachmentsArray[index].FileName = null;
    this.EnquiryObj.EnquiryDetails.QuoteAttachmentsArray[index].FilePath = null;
    this.EnquiryObj.EnquiryDetails.QuoteAttachmentsArray.splice(index, 1);
  }
  removeBOMAttachments(flag) {
    if (flag == 1) {
      this.EnquiryObj.RemoveFiles.push(this.EnquiryObj.EnquiryDetails.BOM_filepath);
    }
    this.isBOMAttachment = false;
    this.EnquiryObj.EnquiryDetails.BOMAttachment = [];
    this.EnquiryObj.EnquiryDetails.BOM_filename = null;
    this.EnquiryObj.EnquiryDetails.BOM_filepath = null;
  }

  removeInvoiceAttachments() {
    this.isInvoiceAttachment = false;
    this.myInputVariable.nativeElement.value = "";
    this.EnquiryObj.EnquiryDetails.InvoiceAttachment = undefined;
  }

  SetStatus(rule, myform: any) {
    this.PreviousStatus = this.EnquiryObj.EnquiryDetails.Status;
    this.EnquiryObj.EnquiryDetails.Status = rule[0].workflowNextStatusId;
    this.AddEnquiry();
  }

  AddEnquiry() {

    // Input field validation in row of CA
    let inputs = document.querySelectorAll('.socss')
    inputs.forEach(input => {
      input.classList.add('error')  
    })

    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'EnquiryAdded')[0]?.Pk_Id
      || this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'RFQSaveAsDraft')[0]?.Pk_Id
      || this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'RFQSubmittedbySalesEngg')[0]?.Pk_Id) {

      this.checkRFQGridValidation();
      if (!this.IsRFQDataValid) {
        return;
      }
      if (this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition == 'Standard') {
        let tnc = this.EnquiryObj.TermsAndConditions.filter(x => (x.TnCValue == undefined || x.TnCValue == null || x.TnCValue == ''));
        if (tnc.length > 0) {
          return;
        }
        this.EnquiryObj.CustomTermsAndConditions = null;
      }

      if (this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition == 'Customized') {
        let ctnc = this.EnquiryObj.CustomTermsAndConditions.filter(x => (x.CustomValues == undefined || x.CustomValues == null))
        if (ctnc.length > 0) {
          return;
        }
        this.EnquiryObj.TermsAndConditions = null;
      }


    }
    //Dont proceed if the BOM attachment is not present
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'SendBOMandProposal')[0]?.Pk_Id || this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'QuotationSenttoCustomerService')[0]?.Pk_Id ||
      this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'RFQsentbyL1toL2')[0]?.Pk_Id) {
      if ((this.EnquiryObj.EnquiryDetails.BOMAttachment == null || this.EnquiryObj.EnquiryDetails.BOMAttachment == undefined || this.EnquiryObj.EnquiryDetails.BOMAttachment.length == 0) &&
        (this.EnquiryObj.EnquiryDetails.BOM_filename == null || this.EnquiryObj.EnquiryDetails.BOM_filename == undefined || this.EnquiryObj.EnquiryDetails.BOM_filename == "")) {
        this.toastr.info('BOM attachment is required.');
        return;
      }
    }

    // //Dont proceed if the Po attachment is not present
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'EnquiryOrderConfirmedbySalesEngg')[0]?.Pk_Id) {
      if ((this.EnquiryObj.EnquiryDetails.POAttchments == null || this.EnquiryObj.EnquiryDetails.POAttchments == undefined || this.EnquiryObj.EnquiryDetails.POAttchments.length == 0) &&
        (this.EnquiryObj.EnquiryDetails.PO_filename == null || this.EnquiryObj.EnquiryDetails.PO_filename == undefined || this.EnquiryObj.EnquiryDetails.PO_filename == "")) {
        this.toastr.info('PO attachment is required.');
        return;
      }
    }

    // //Dont proceed if the Invoice attachment is not present
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'AccountsConfirmInvoiceNo')[0]?.Pk_Id) {
      if ((this.EnquiryObj.EnquiryDetails.InvoiceAttachment == null || this.EnquiryObj.EnquiryDetails.InvoiceAttachment == undefined || this.EnquiryObj.EnquiryDetails.InvoiceAttachment.length == 0) &&
        (this.EnquiryObj.EnquiryDetails.Invoice_filename == null || this.EnquiryObj.EnquiryDetails.Invoice_filename == undefined || this.EnquiryObj.EnquiryDetails.Invoice_filename == "")) {
        this.EnquiryObj.EnquiryDetails.Status = this.enquiryStatusList.filter(x => x.Status == 'MaterialReadyForDispatch')[0]?.Pk_Id;
        this.toastr.info('Invoice attachment is required.');
        return;
      }
    }


    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'EnquiryOrderConfirmedbySalesEngg')[0]?.Pk_Id) {
      if ((this.EnquiryObj.EnquiryDetails.POAttchments == undefined || this.EnquiryObj.EnquiryDetails.POAttchments == null || this.EnquiryObj.EnquiryDetails.POAttchments.length == 0)) {
        return;
      }
    }


    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'OrdersentforMngmtApproval_SalesDirector')[0]?.Pk_Id) {
      if (this.isPOAttacmentDelete) {
        if ((this.EnquiryObj.EnquiryDetails.POAttchments == undefined || this.EnquiryObj.EnquiryDetails.POAttchments == null || this.EnquiryObj.EnquiryDetails.POAttchments.length == 0)) {
          return;
        }
      }

    }

    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'OrderConfirmedbyCustomerServiceSendSO')[0]?.Pk_Id) {
      if (this.isPOAttacmentDelete) {
        if ((this.EnquiryObj.EnquiryDetails.POAttchments == undefined || this.EnquiryObj.EnquiryDetails.POAttchments == null || this.EnquiryObj.EnquiryDetails.POAttchments.length == 0)) {
          return;
        }
      }
    }
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'AccountsConfirmInvoiceNo')[0]?.Pk_Id) {
      if (this.EnquiryObj.EnquiryDetails.InvoiceAttachment == undefined) {
        this.EnquiryObj.EnquiryDetails.Status = this.enquiryStatusList.filter(x => x.Status == 'MaterialReadyForDispatch')[0]?.Pk_Id
        return;
      }
    }
    // if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'OrdersentforMngmtApproval_SalesDirector')[0]?.Pk_Id ||
    //   this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'OrderConfirmedbyCustomerServiceSendSO')[0]?.Pk_Id) {
    //   let sum: number = 0;
    //   this.EnquiryObj.POValues.forEach(element => {
    //     element.ProductModelPOValue = (element.ProductModelPOValue == null || element.ProductModelPOValue == '' || element.ProductModelPOValue == undefined)
    //       ? '0' : element.ProductModelPOValue;
    //     sum = sum + parseInt(element.ProductModelPOValue);
    //     element.QUotationValueValidation = false;
    //   });
    //   if (sum.toString() != this.EnquiryObj.EnquiryDetails.POValue) {
    //     document.getElementById("povalue0").focus();
    //     this.toastr.error('Total PO Value should match with Product wise PO Value.');
    //     // info('Total PO Value should match with Product wise PO Value.');
    //     this.EnquiryObj.EnquiryDetails.Status = this.PreviousStatus;
    //     this.UpdatePO = false;
    //     return;
    //   }
    // }

    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'OrdersentforMngmtApproval_SalesDirector')[0]?.Pk_Id ||
      this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'OrderConfirmedbyCustomerServiceSendSO')[0]?.Pk_Id) {

      if (this.totalCalculatedPriceOfAllProductModel.toString() != this.EnquiryObj.EnquiryDetails.POValue) {
        document.getElementById("povalue0").focus();
        this.toastr.error('Total PO Value should match with Final PO Value including all charges.');
        // info('Total PO Value should match with Product wise PO Value.');
        this.EnquiryObj.EnquiryDetails.Status = this.PreviousStatus;
        this.UpdatePO = false;
        return;
      }

      

      // this.EnquiryObj.DynamicTCDetailObj.forEach(element => {
      //   if (element.TndCName == undefined || element.TndCName == null || element.TndCName == "") {
      //     element.TndCNameValidation = true;
      //   }
      //   if (element.TndCValue == undefined || element.TndCValue == null || element.TndCValue == "") {
      //     element.TndCValueValidation = true;
      //   }
      // });
      
    }

    setTimeout(() => this.openPopup());
  }
  openPopup() {
    var confimrmsg = this.AppGlobals.successMessages.filter(x => x.id == this.EnquiryObj.EnquiryDetails.Status)[0];
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: confimrmsg.confirmmsg
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.updateLoaderEvent.emit(true);
        const frmData = new FormData();
        this.EnquiryObj.RFQObject.forEach(item => {
          item.IsRowDeleted = false;
        });
        if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.EnquiryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.TentativeDeliveryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.year, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.month, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.RequestedDeliveryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.year, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.month, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.EnquiryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day)));
        }
        if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.year, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.month, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.day)));
        }
        if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.year, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.month, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.day)));
        }
        // if (this.EnquiryObj.POValues.ExpectedDeliveryDate != undefined) {
        //   this.EnquiryObj.POValues.ExpectedDeliveryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.year, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.month, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.day)));
        // }
        //if (this.EnquiryObj.POValues != undefined) {
          // this.EnquiryObj.POValues.forEach(poValue => {
          //   if (poValue.ExpectedDeliveryDate) {
          //     const invoiceDate = new Date(poValue.ExpectedDeliveryDate);
          //     const localDate = new Date(invoiceDate.getTime() - invoiceDate.getTimezoneOffset() * 60000)
          //       .toISOString()
          //       .split('T')[0];
          //     poValue.ExpectedDeliveryDate = localDate;
          //   }
          // });
        // }
        

        if (this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition == "Customized") {
          this.EnquiryObj.TermsAndConditions = null;

          if (this.QGRFQEditable == true) {
            this.EnquiryObj.CustomTermsAndConditions;

          } else {
            this.EnquiryObj.CustomTermsAndConditions = this.custtermscond;
          }
        }

        let EnquiryObj = JSON.stringify(this.EnquiryObj);
        frmData.append("EnquiryModel", EnquiryObj);


        if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments != null) {
          for (let i = 0; i < this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length; i++) {
            frmData.append("QuoteGenAttachments[" + i + "]", this.EnquiryObj.EnquiryDetails.QuoteGenAttachments[i]);
          }
        }
        for (let i = 0; i < this.EnquiryObj.RFQObject.length; i++) {
          if (this.EnquiryObj.RFQObject[i].MDSAttachment != undefined) {
            frmData.append("MDSAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].MDSAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].ITCAttachment != undefined) {
            frmData.append("ITCAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].ITCAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].CustomerAttachment != undefined) {
            frmData.append("CustomerAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].CustomerAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].WeissAttachment != undefined) {
            frmData.append("WeissAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].WeissAttachment[0]);
          }
        }
        if (this.EnquiryObj.EnquiryDetails.BOMAttachment) {
          frmData.append("BOMAttachment", this.EnquiryObj.EnquiryDetails.BOMAttachment[0]);
        }
        if (this.EnquiryObj.EnquiryDetails.InvoiceAttachment) {
          frmData.append("InvoiceAttachment", this.EnquiryObj.EnquiryDetails.InvoiceAttachment[0]);
        }
        if (this.EnquiryObj.EnquiryDetails.POAttchments) {
          frmData.append("POAttchments", this.EnquiryObj.EnquiryDetails.POAttchments[0]);
        }

        this.commonService.PostData("Enquiry", "Add", frmData, null).subscribe((data: any) => {
          this.customerDrpdnDetails = data;
          this.EnquiryObj.EnquiryDetails.EnquiryId = data;
          if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'OANoGenerated')[0]?.Pk_Id) {
            this.toastr.success("OA Number generated successfully");
          }
          else {
            this.toastr.success(confimrmsg.successmsg);
          }

          this.updateLoaderEvent.emit(false);
          if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'RFQAcceptedbyL1')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'QuotationRevision')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'RFQSubmittedbyL1')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'RFQSubmittedbyL2')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'RFQSubmittedbyL3')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'RFQSubmittedbyCustomerService')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'OANoGenerated')[0]?.Pk_Id) {
            // this.router.navigate(['/EditViewEnquiry/' + this.EnquiryObj.EnquiryDetails.EnquiryId]);
            window.location.reload();
          } else {
            this.router.navigate(['/Enquiry']);
            //this.backtoList();
          }
        }, error => {
          this.toastr.error(confimrmsg.errormsg);
          this.updateLoaderEvent.emit(false);
        });
      } else {
        if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'QuotationRevision')[0]?.Pk_Id) {
          this.chosenItem = "2";
          this.quoterevcomments = this.EnquiryObj.EnquiryDetails.Comments;
          this.EnquiryObj.EnquiryDetails.Comments = "";
        }
        else {

          if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'OrdersentforMngmtApproval_SalesDirector')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x => x.Status == 'OrderConfirmedbyCustomerServiceSendSO')[0]?.Pk_Id) {
            this.UpdatePO = false;
          }
          if (this.PreviousStatus > 0) {
            this.EnquiryObj.EnquiryDetails.Status = this.PreviousStatus;
          }

        }

      }
    });

  }
  povalueChange(i) {
    var povalue: number = parseFloat(this.EnquiryObj.POValues[i].ProductModelPOValue);
    if (povalue == undefined || povalue == null) {
      this.EnquiryObj.POValues[i].QUotationValueValidation = true;
      this.IsPODataValid = false;
    } else if (povalue < 0) {
      this.EnquiryObj.POValues[i].QUotationValueValidation = true;
      this.IsPODataValid = false;
    } else if (povalue % 1 != 0) {
      this.EnquiryObj.POValues[i].QUotationValueValidation = true;
      this.IsPODataValid = false;
    } else if (this.EnquiryObj.POValues[i].ProductModelPOValue.toString().includes(".")) {
      this.EnquiryObj.POValues[i].QUotationValueValidation = true;
      this.IsPODataValid = false;
    }
    else {
      this.EnquiryObj.POValues[i].QUotationValueValidation = false;
    }
  }



  checkRFQGridValidation() {
    var inde = 0;
    this.IsRFQDataValid = true;
    this.EnquiryObj.RFQObject.forEach((element, index) => {
      if (element.ProductLine == undefined || element.ProductLine == null || element.ProductLine == 0) {
        document.getElementById("productline" + index).focus();
        element.ProductLineValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.ProductModel == undefined || element.ProductModel == null || element.ProductModel == 0) {
        document.getElementById("productmodel" + index).focus();
        element.ProductModelValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.Quantity == undefined || element.Quantity == null || element.Quantity == 0) {
        document.getElementById("quantity" + index).focus();
        element.QuantityValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.MDSOption == undefined || element.MDSOption == null || element.MDSOption == 0) {
        document.getElementById("mdsoption" + index).focus();
        element.MDSOptionValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.SalesPrice == undefined || element.SalesPrice == null || element.SalesPrice == 0) {
        document.getElementById("salesprice" + index).focus();
        element.SalesPriceValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.SalesDiscount == undefined || element.SalesDiscount == null) {
        document.getElementById("salesdiscount" + index).focus();
        element.SalesDiscountValidation = true;
        this.IsRFQDataValid = false;
      }

    });

  }

  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }

  downloadPDF() {
    const url = AppGlobals.baseAppUrl + 'uploads/pdf/' + this.EnquiryObj.EnquiryDetails.QuotationPdf_filename;
    window.open(url, "download");
  }

  DownloadPDFfromServer(Filename: any) {
    this.commonService.gereatePDF('Enquiry', 'DownloadPDF', Filename).subscribe((data: any) => {

      importedSaveAs(data, Filename);
    });
  }
  checkattachment() {
    this.rfqattpresent = false;
    this.EnquiryObj.RFQObject.forEach(element => {
      if (!(element.MDS_filename == null || element.MDS_filename == undefined || element.MDS_filename == '')) {
        this.rfqattpresent = true;
      }
      if (!(element.ITC_filename == null || element.ITC_filename == undefined || element.ITC_filename == '')) {
        this.rfqattpresent = true;
      }
      if (!(element.Weiss_filename == null || element.Weiss_filename == undefined || element.Weiss_filename == '')) {
        this.rfqattpresent = true;
      }
      if (!(element.Customer_filename == null || element.Customer_filename == undefined || element.Customer_filename == '')) {
        this.rfqattpresent = true;
      }
    });
  }
  DownloadAllAttachments() {
    this.checkattachment();
    if (!this.rfqattpresent) {
      this.toastr.info('No attachment present.');
      return;
    }
    this.updateLoaderEvent.emit(true);
    let queryString = "";
    queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
    this.commonService.GetData('Enquiry', 'Download', queryString).subscribe((data: any) => {
      var url = AppGlobals.ZipDownloadUrl + data + '.zip';
      window.open(url, "_blank");
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }



  public saveAsFile(pdfurl: any, fileName: string): void {
    //const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(this.fileDownloadPath + pdfurl, fileName);
  }

  EditPO() {
    this.UpdatePO = true;

    setTimeout(() => {
      this.editpovalue.nativeElement.focus();
    }, 0);
  }



  onPOQuatityChange(index) {
    //let strquantity = this.EnquiryObj.POValues[index].POQuantity.toString();   
    if ((this.EnquiryObj.POValues[index].POQuantity == 0 || this.EnquiryObj.POValues[index].POQuantity == null || this.EnquiryObj.POValues[index].POQuantity == undefined)) {
      this.EnquiryObj.POValues[index].POQuantity = 0;
    }
    else if (this.EnquiryObj.POValues[index].POQuantity % 1 != 0) {
      this.EnquiryObj.POValues[index].QuantityValidation = true;
      return;
    }
    else {
      this.EnquiryObj.POValues[index].QuantityValidation = false;
    }
    if (this.EnquiryObj.POValues[index].POQuantity > 1000) {
      this.EnquiryObj.POValues[index].QuantityRangeValidation = true;
      return;
    }
    else {
      this.EnquiryObj.POValues[index].QuantityRangeValidation = false;
    }

    this.calculateTotalPricesForProduct(index, 1);
  }


  calculateTotalPricesForProduct(index, flag) {
    this.poPrice = 0;
    var POValues = this.EnquiryObj.POValues[index];

    POValues.POUnitPrice = POValues?.POUnitPrice == undefined ? 0 : POValues.POUnitPrice;
    POValues.ProductModelPOValue = POValues?.ProductModelPOValue == undefined ? 0 : POValues.ProductModelPOValue;

    var percent = 100 - POValues.PODiscount;
    this.poPrice = POValues.POUnitPrice * POValues.POQuantity;
    POValues.ProductModelPOValue = POValues.POUnitPrice * POValues.POQuantity * (percent / 100);
    var finalpoValue = this.poPrice;
    var finalProductModelPOValue = POValues.ProductModelPOValue

    this.poPrice = Math.round(finalpoValue);
    POValues.ProductModelPOValue = Math.round(finalProductModelPOValue);

    // let ProductModelWisePOValue = POValues.ProductModelPOValue;
    // this.totalCalculatedPriceOfAllProductModel = this.totalCalculatedPriceOfAllProductModel + ProductModelWisePOValue;
    //this.totalCalculatedPriceAfterIncludingAllCharges = POValues.ProductModelPOValue;

    this.calculateTotalProductWisePOValue();
  }

  onPODiscountChange(i) {
    if (this.EnquiryObj.POValues[i].PODiscount < 0) {
      this.EnquiryObj.POValues[i].PODiscount = 0;
    }
    else if (this.EnquiryObj.POValues[i].PODiscount > 100) {
      this.EnquiryObj.POValues[i].PODiscount = 0;
      return;
    }
 
    else if (this.EnquiryObj.POValues[i].PODiscount != 0) {
    
      this.calculateTotalPricesForProduct(i,1);

    }
    else if (this.EnquiryObj.POValues[i].PODiscount == 0) {
   
      this.calculateTotalPricesForProduct(i,1);
    }
  }

  onPOUnitPriceChange(index) {
    let strprice = this.EnquiryObj.POValues[index].POUnitPrice.toString();
    if ((this.EnquiryObj.POValues[index].POUnitPrice == 0 || this.EnquiryObj.POValues[index].POUnitPrice == null || this.EnquiryObj.POValues[index].POUnitPrice == undefined)) {
      this.EnquiryObj.POValues[index].POUnitPrice = 0;
    }
    // else if (this.EnquiryObj.POValues[index].POUnitPrice > 9999999) {
    //   this.EnquiryObj.POValues[index].PoUnitPriceValidation = true;
    //   return;
    // }
    else if (strprice.includes(".")) {
      alert('PO unit price must be an integer');
      this.EnquiryObj.POValues[index].POUnitPrice = 0;
      this.calculateTotalPricesForProduct(index, 1);
      return;
    }
    else {
      this.EnquiryObj.POValues[index].PoUnitPriceValidation = false;
    }

    this.calculateTotalPricesForProduct(index, 1);
  }

  getAllWorkFlow() {
    let tracker=this.commonService.GetData("Workflow", "GetAll", null);
    tracker.subscribe((data: any) => {   
      //handle null
      if(data!=null) {
        var workflowData = data;
        this.workflowId=workflowData.filter(x=>x.document_type_code == "SALES_ORDER")[0].workflow_id;
        this.getWorkflowActions(this.workflowId);
      }
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
    return  tracker;
  }

  getWorkflowActions(workflowId:any) {
    let queryString = "";
    queryString = "enquiryId=" + this.EnquiryObj.EnquiryDetails.EnquiryId + "&workflowId=" + workflowId;

    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("EnquiryWorkflowTransitionRules", "GetTransitionRulesByStatus", queryString).subscribe((data: any) => {
      this.workflowActionList = data;
      this.cd.detectChanges();
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  getEnquiryStatus() {
    this.commonService.GetData("Enquiry", "GetEnquiryStatus", null).subscribe((data: any) => {
      this.enquiryStatusList = data;
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  OnWorkflowAction(myform7: NgForm, workflowActionId: number) {
    var rule = this.workflowActionList.filter(x => x.workflowActionId == workflowActionId);
    myform7.control.markAllAsTouched();
    if (myform7.invalid) {
      this.toastr.error("Please fill required information");
      return;
    }
  
    var emptyPOModels = this.EnquiryObj.POValues.filter(x=>x.POQuantity<=0);
    if(emptyPOModels.length == this.EnquiryObj.POValues.length)
    {
      this.toastr.error("Please fill PO quantity greater than 0, at least one product model required to continue further process.");
      return;
    }
   
    this.SetStatus(rule, myform7);

  }

  getUserRoles(){
    this.commonService.GetData("Account", "GetAll", null).subscribe((data: any) => {
      this.UserRolesData = data;
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  sendbackRules:EnquirySendBack[]=[];
  getSendbackWorkfow()
  {
    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("EnquiryWorkflowTransitionRules","GetWorkflowSendbackRule", null).subscribe((data: any) => {
      this.sendbackRules=data;
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  rule:EnquirySendBack;
  SendBack()
  {
    var quotationRule= this.sendbackRules.filter(x=>x.WorkflowId==this.workflowId);
    this.rule=quotationRule[0];

    const modalRef = this.modalService.open(SendBackCommentComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });

    var sendBackObj = {
      EnquiryId: this.EnquiryObj.EnquiryDetails.EnquiryId,
      workflowId: this.workflowId,
      finalrule: this.rule
    }
    modalRef.componentInstance.sendBackObj = sendBackObj;
    modalRef.result.then((result) => {
      this.closeResult = `Closed with:${result}`;
      if (this.closeResult !== 'Closed with:Close click') {
        this.ngOnInit();
      }
    });
    // this.rule.EnquiryId=this.EnquiryObj.EnquiryDetails.EnquiryId;

    // let rules = JSON.stringify(this.rule);
    // const frmData = new FormData();
    // frmData.append("rule", rules);
    // this.commonService.PostData("EnquiryWorkflowTransitionRules","SetStatus",frmData,"",null).subscribe(data=>{
    //   this.toastr.success("Enquiry sent one step back");
    //   window.location.reload();
    // });    
  }

  getCustomerAddresses(CustomerId : number)
  {
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + CustomerId;
    this.commonService.GetData('Customer', 'GetAddressesById', queryString).subscribe((data: any) => {
      this.CustomerAddressList = data;
      this.showLoader = false;
    });
  }

  AddNewRow(index)
  {
    if (this.EnquiryObj.DynamicTCDetailObj[index].TndCName != undefined && this.EnquiryObj.DynamicTCDetailObj[index].TndCName != "" &&
      this.EnquiryObj.DynamicTCDetailObj[index].TndCValue != undefined && this.EnquiryObj.DynamicTCDetailObj[index].TndCValue != "")
    {
      this.EnquiryObj.DynamicTCDetailObj[index].IsRowDeleted = true;
      var obj = new DynamicTermsAndCondition();
      this.EnquiryObj.DynamicTCDetailObj.push(obj);
    }
    else{
      this.toastr.info("Please fill mandatory details before adding new row.");
    }
  }


  deleterow(index:any)
  {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to delete this row?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (index != null) {
          this.EnquiryObj.DynamicTCDetailObj.splice(index, 1);
        } else {
          this.EnquiryObj.DynamicTCDetailObj.pop();
        }
      }
    });
  }

  onClick_IncludeAllSOCharges(value : any)
  {
    if(value == true)
    { 
      this.EnquiryObj.EnquiryDetails.IsIncludedAllSOCharges = true;
    }
    else
    {
      this.EnquiryObj.EnquiryDetails.IsIncludedAllSOCharges = false;
    }
  }

  calculateTotalPriceWithAllSOCharges(value: boolean)
  {
    this.IsIncludedAllSOChargesResult = value
    this.calculatedTotalPrice = 0;
    this.calculatedTotalPrice = this.EnquiryObj.EnquiryDetails.PackageAndForward +
      this.EnquiryObj.EnquiryDetails.TransferCharge +
      this.EnquiryObj.EnquiryDetails.OtherTax

    if(this.IsIncludedAllSOChargesResult == true && this.EnquiryObj.EnquiryDetails.SalesOrderSpecialPkgDiscount == 0)
    {
      //this.totalCalculatedPriceAfterIncludingAllCharges = this.calculatedTotalPrice + parseFloat(this.EnquiryObj.EnquiryDetails.POValue);
      this.totalCalculatedPriceOfAllProductModel = this.calculatedTotalPrice + this.totalCalculatedPriceAfterIncludingAllCharges;
    }
    else if(this.IsIncludedAllSOChargesResult == false && this.EnquiryObj.EnquiryDetails.SalesOrderSpecialPkgDiscount == 0)
    {
      //this.totalCalculatedPriceAfterIncludingAllCharges = parseFloat(this.EnquiryObj.EnquiryDetails.POValue);
      this.totalCalculatedPriceOfAllProductModel = this.totalCalculatedPriceAfterIncludingAllCharges;
    }
    else if(this.IsIncludedAllSOChargesResult == true && this.EnquiryObj.EnquiryDetails.SalesOrderSpecialPkgDiscount != 0)
    {
      this.totalCalculatedPriceOfAllProductModel = this.calculatedTotalPrice + this.tempTotalCalculatedPriceOfAllProductModel;
    }
    else if(this.IsIncludedAllSOChargesResult == false && this.EnquiryObj.EnquiryDetails.SalesOrderSpecialPkgDiscount != 0)
    {
      this.totalCalculatedPriceOfAllProductModel = this.tempTotalCalculatedPriceOfAllProductModel;
    }
  }

  calculateTotalProductWisePOValue() {
    this.totalCalculatedPriceOfAllProductModel = 0;
    this.EnquiryObj.POValues.forEach(element => {
      element.ProductModelPOValue = (element.ProductModelPOValue == null || element.ProductModelPOValue == '' || element.ProductModelPOValue == undefined)
        ? '0' : element.ProductModelPOValue;

      this.totalCalculatedPriceOfAllProductModel = this.totalCalculatedPriceOfAllProductModel + parseInt(element.ProductModelPOValue);
      //element.QUotationValueValidation = false;
    });

    this.totalCalculatedPriceAfterIncludingAllCharges = this.totalCalculatedPriceOfAllProductModel;

    this.onSalesOrderSpecialPkgDiscountChange();

  }

  
  allowDecimal(event: any) {
    // Allow decimal point and only two decimal places
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!regex.test(event.target.value + inputChar)) {
      event.preventDefault();
    }
    if ((this.specialPkgDiscount > 100)) {
      //alert("Discount cannot be Greater than 100");
      this.specialPkgDiscount = 0;
      return;
    }
  }

  onSalesOrderSpecialPkgDiscountChange()
  {
    if ((this.EnquiryObj.EnquiryDetails.SalesOrderSpecialPkgDiscount < 0))
    {
      this.EnquiryObj.EnquiryDetails.SalesOrderSpecialPkgDiscount = 0;
      return;
    }
    else if ((this.EnquiryObj.EnquiryDetails.SalesOrderSpecialPkgDiscount > 100)) 
    {
      this.EnquiryObj.EnquiryDetails.SalesOrderSpecialPkgDiscount = 0;
      return;
    }
    else if (this.EnquiryObj.EnquiryDetails.SalesOrderSpecialPkgDiscount != 0)
    {
      var percent = 100 - this.EnquiryObj.EnquiryDetails.SalesOrderSpecialPkgDiscount;
      var calculate = this.totalCalculatedPriceAfterIncludingAllCharges * (percent / 100);
      
      this.tempTotalCalculatedPriceOfAllProductModel = Math.round(calculate);
     
      this.calculateTotalPriceWithAllSOCharges(this.EnquiryObj.EnquiryDetails.IsIncludedAllSOCharges);
    }
    else if (this.EnquiryObj.EnquiryDetails.SalesOrderSpecialPkgDiscount == 0)
    {
      
      this.calculateTotalPriceWithAllSOCharges(this.EnquiryObj.EnquiryDetails.IsIncludedAllSOCharges);

    }

  }

}

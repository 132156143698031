<div class="col-lg-12 mb-4 table-responsive" *ngIf="!isprocessing">
    <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="myform7" #myform7="ngForm"
        appFocusInvalidInput novalidate>
        <div class="multisteps-form__panel shadow rounded bg-white js-active" data-animation="fadeIn">
            <div class="multisteps-form__content">
                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text">
                                    Sales Order(SO) Generation
                                </h3>
                            </div>
                        </div>
                        <div class="m-portlet__head-tools">
                            <button *ngIf="this.UserRole == roleOfAdmin"
                                class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                (click)="SendBack()">Send Back</button>
                        </div>
                    </div>
                    <div class="markedFields">
                        <span style="margin-left:10px;">* Marked fields are
                            mandatory</span>
                    </div>
                    <div class="m-portlet__body">
                        <div class="form-group m-form__group row">

                            <label class="col-lg-2 col-form-label">
                                BOM (Bill Of Materials):</label>
                            <div class="col-lg-3 pt-2 filenamemobile">
                                <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.BOM_filepath}}"
                                    target="_blank">{{EnquiryObj.EnquiryDetails.BOM_filename}}</a>
                            </div>

                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">View
                                Quotation:</label>
                            <div class="col-lg-3 pt-3 filenamemobile">
                                <a target="_blank"
                                    href="{{QuotationDownloadPath}}{{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}">
                                    {{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}
                                </a>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">

                            <label class="col-lg-2 col-form-label">PO
                                Attachment:</label>
                            <div class="col-lg-3 pt-3 filenamemobile">
                                <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.PO_filepath}}"
                                    target="_blank">{{EnquiryObj.EnquiryDetails.PO_filename}}</a>
                            </div>

                            <div class="offset-1"></div>

                            <label class="col-lg-2 col-form-label">
                                PO Date:</label>
                            <div class="col-lg-3 pt-3 dateSize">
                                {{EnquiryObj.EnquiryDetails.POSubmittedOn | date}}

                            </div>
                        </div>


                        <div class="form-group m-form__group row" *ngIf="UpdatePO">
                            <label class="col-lg-2 col-form-label">PO
                                Value:<span style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-3">
                                <input type="text" class="form-control m-input" placeholder="Enter PO Value"
                                    maxlength="30" min="0" pattern="[0-9]+" id="updatepovalue" name="updatepovalue"
                                    [(ngModel)]="EnquiryObj.EnquiryDetails.POValue"
                                    (keypress)="AvoidSpace($event);isNumberKey($event);" #updatepovalue="ngModel"
                                    #editpovalue
                                    [ngClass]="{ 'is-invalid': (myform7.submitted && updatepovalue.invalid) || (updatepovalue?.touched && updatepovalue.invalid) }"
                                    required>
                                <div *ngIf="(myform7.submitted && updatepovalue.invalid) || (updatepovalue?.touched && updatepovalue.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="myform7.hasError('pattern', 'updatepovalue')">
                                        PO Value must be an integer
                                    </div>
                                    <div *ngIf="updatepovalue.errors.required">
                                        PO value is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Attach
                                PO/LOI:<span style="color:#f4516c;">&nbsp;*</span></label>

                            <div *ngIf="(EnquiryObj.EnquiryDetails.PO_filename==undefined ||
                                            EnquiryObj.EnquiryDetails.PO_filename=='' || 
                                            EnquiryObj.EnquiryDetails.PO_filename==null)" class="col-lg-4 pt-1">
                                <div class="file btn btn-sm btn-warning">
                                    Attach PO/LOI
                                    <input type="file" class="form-control-file" id="ecfile" name="ecfile"
                                        (change)="editFileDetails($event,0)" required />
                                </div>
                                <br /><span *ngIf="(EnquiryObj.EnquiryDetails.PO_filename==undefined ||
                                                EnquiryObj.EnquiryDetails.PO_filename=='' || 
                                                EnquiryObj.EnquiryDetails.PO_filename==null)" class="fileext">
                                    {{AppGlobals.supportedFileFormatsForOtherAttachments}}</span>
                                <div *ngIf="(EnquiryObj.EnquiryDetails.PO_filename==undefined ||
                                                EnquiryObj.EnquiryDetails.PO_filename=='' || 
                                                EnquiryObj.EnquiryDetails.PO_filename==null)"
                                    style="color:#f4516c;font-size: 80%;">
                                    PO attachment is
                                    required
                                </div>

                                <div class="filenamemobile">
                                    {{EnquiryObj.EnquiryDetails.POAttchments
                                    != undefined &&
                                    EnquiryObj.EnquiryDetails.POAttchments[0]
                                    != undefined ?
                                    EnquiryObj.EnquiryDetails.POAttchments[0].name:
                                    ""}}
                                    <i class="flaticon-delete"
                                        *ngIf="!(EnquiryObj.EnquiryDetails.POAttchments == undefined || EnquiryObj.EnquiryDetails.POAttchments?.length == 0)"
                                        style="color:#f4516c" (click)="deletePOAttachment(0)" title="Remove"><br></i>
                                </div>



                            </div>
                            <div class="col-lg-3" *ngIf="!(EnquiryObj.EnquiryDetails.PO_filename==undefined ||
                                        EnquiryObj.EnquiryDetails.PO_filename=='' || 
                                        EnquiryObj.EnquiryDetails.PO_filename==null)">
                                <div class="filenamemobile" style="padding-top: 3px;">
                                    <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.PO_filepath}}"
                                        target="_blank">{{EnquiryObj.EnquiryDetails.PO_filename}}</a>
                                    <i class="flaticon-delete" style="color:#f4516c" (click)="deletePOAttachment(1)"
                                        title="Remove"><br></i>
                                </div>
                            </div>

                        </div>

                        <div class="form-group m-form__group row">

                            <label class="col-lg-2 col-form-label">Total PO
                                Values:</label>
                            <div class="col-lg-3 pt-3">
                                <b> ₹
                                    {{EnquiryObj.EnquiryDetails.POValue |
                                    indianCurrency}}/-</b>
                                <br />
                                <span style="color:#f4516c;">(The PO value
                                    to be entered product wise below. Please
                                    note that total PO Value should matched
                                    with product wise.)</span>
                            </div>

                            <div class="offset-1"></div>

                            <label class="col-lg-2 col-form-label">Purchase Order
                                No:<span style="color:#f4516c;">&nbsp;*</span>
                            </label>

                            <div class="col-lg-3">
                                <input class="form-control m-input" type="text" name="PONumber"
                                    [(ngModel)]="EnquiryObj.EnquiryDetails.PurchaseOrderNo" #PONumber="ngModel"
                                    maxlength="50"
                                    [ngClass]="{  'is-invalid':myform7.submitted  && PONumber.invalid || PONumber?.touched && PONumber.invalid}"
                                    placeholder="Enter Purchase Order No" (keypress)="AvoidSpace($event)"
                                    (change)="EnquiryObj.EnquiryDetails.PurchaseOrderNo = EnquiryObj.EnquiryDetails.PurchaseOrderNo.trim()"
                                    [disabled]="!isSOEditable" required>
                                <div class="invalid-feedback"
                                    *ngIf="myform7.submitted && PONumber.invalid || PONumber?.touched && PONumber.invalid">
                                    <div *ngIf="myform7.hasError('required', 'PONumber')">
                                        Purchase Order Number is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Bill To
                                Address:<span style="color:#f4516c;">&nbsp;*</span></label>

                            <div class="col-lg-3">
                                <select class="form-control " id="BillToAddress" name="BillToAddress"
                                    #BillToAddress="ngModel" [disabled]="!isSOEditable"
                                    [(ngModel)]="EnquiryObj.EnquiryDetails.BillToAddress"
                                    [ngClass]="{ 'is-invalid': (myform7.submitted && BillToAddress.invalid) || (BillToAddress?.touched && BillToAddress.invalid)}"
                                    required>
                                    <option value=null disabled>
                                        Select Address</option>
                                    <option *ngFor="let address of CustomerAddressList" [value]="address">
                                        {{address}}
                                    </option>

                                </select>
                                <div *ngIf="(myform7.submitted && BillToAddress.invalid) || (BillToAddress?.touched && BillToAddress.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="BillToAddress.errors.required">
                                        Bill to address is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Ship To
                                Address:<span style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-3">
                                <select class="form-control " id="ShipToAddress" name="ShipToAddress"
                                    #ShipToAddress="ngModel" [disabled]="!isSOEditable"
                                    [(ngModel)]="EnquiryObj.EnquiryDetails.ShipToAddress"
                                    [ngClass]="{ 'is-invalid': (myform7.submitted && ShipToAddress.invalid) || (ShipToAddress?.touched && ShipToAddress.invalid)}"
                                    required>
                                    <option value=null disabled>
                                        Select Address</option>
                                    <option *ngFor="let address of CustomerAddressList" [value]="address">
                                        {{address}}
                                    </option>

                                </select>
                                <div *ngIf="(myform7.submitted && ShipToAddress.invalid) || (ShipToAddress?.touched && ShipToAddress.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="ShipToAddress.errors.required">
                                        Ship to address is
                                        required
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Customer Service
                                Name:<span style="color:#f4516c;">&nbsp;*</span>
                            </label>

                            <div class="col-lg-3">
                                <input class="form-control m-input" type="text" name="CustomerServiceName"
                                    [(ngModel)]="EnquiryObj.CustomerServiceDetails.CustomerServiceName"
                                    #CustomerServiceName="ngModel" maxlength="50" placeholder="Enter Purchase Order No"
                                    (keypress)="AvoidSpace($event)" disabled required>

                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Email:
                                <span style="color:#f4516c;">&nbsp;*</span>
                            </label>

                            <div class="col-lg-3">
                                <input class="form-control m-input" type="text" name="CustomerServiceEmail"
                                    [(ngModel)]="EnquiryObj.CustomerServiceDetails.CustomerServiceEmail"
                                    #CustomerServiceEmail="ngModel" maxlength="50" placeholder="Enter Email"
                                    (keypress)="AvoidSpace($event)" disabled required>

                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Contact Number:
                                <span style="color:#f4516c;">&nbsp;*</span>
                            </label>
                            <div class="col-lg-3">
                                <input class="form-control m-input" type="text" name="CustomerServiceContactNo"
                                    [(ngModel)]="EnquiryObj.CustomerServiceDetails.CustomerServiceContactNo"
                                    #CustomerServiceContactNo="ngModel" maxlength="50"
                                    placeholder="Enter Contact Number" (keypress)="AvoidSpace($event)" disabled
                                    required>

                            </div>

                            <div class="offset-1"></div>

                            <label class="col-lg-2 col-form-label">Customer Number:
                                <span style="color:#f4516c;">&nbsp;*</span>
                            </label>
                            <div class="col-lg-3">
                                <input class="form-control m-input" type="text" name="CustomerCode"
                                    [(ngModel)]="EnquiryObj.EnqCustomerDetails.CustomerCode" #CustomerCode="ngModel"
                                    maxlength="50" placeholder="Enter Customer Code" (keypress)="AvoidSpace($event)"
                                    disabled required>

                            </div>
                        </div>


                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">PO
                                Values:<span style="color:#f4516c;">&nbsp;*</span></label>
                        </div>

                        <div class="table-responsive">
                            <table
                                class="table order-list table-striped- table-bordered table-hover table-edit table-cust"
                                id="salesordergeneration">
                                <thead>
                                    <tr>
                                        <th>Product Model</th>
                                        <!-- <th>Part Number</th> -->
                                        <th>Qty</th>
                                        <th>Disc(%)</th>
                                        <th>Quote Value(₹)</th>
                                        <!-- <th>Expected Delivery Date</th> -->
                                        <th>Variant1 Discount</th>
                                        <th>Variant2 Discount</th>
                                        <th>Variant3 Discount</th>
                                        <th>Variant4 Discount</th>
                                        <th>PO Qty</th>
                                        <th class="myTableHeader">PO Unit Price</th>
                                        <th>PO Disc(%)</th>
                                        <th class="myTableHeader">PO Value(₹)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of EnquiryObj.POValues;let i = index;">

                                        <td>
                                            {{item.ProductModelName}}
                                        </td>
                                        <!-- <td
                                            style="width:100px; vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                            <input type="text" class="form-control p-1" min="0"
                                                id="partnumber{{i}}" name="partnumber{{i}}"
                                                [(ngModel)]="item.PartNumber"
                                                #partnumber="ngModel"
                                                >
                                            <div class="invalid-feedback">
                                                <div *ngIf="item.PartNumber">
                                                    Please enter Part Number
                                                </div>

                                            </div>

                                        </td> -->
                                        <td>
                                            {{item.QuotationQuantity}}
                                        </td>
                                        <td>
                                            {{item.QuotationDiscount}}
                                        </td>
                                        <td>
                                            {{item.QUotationValue | indianCurrency }}
                                        </td>
                                        <!-- <td
                                            style="width:100px; vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                            <input type="date" class="form-control p-1"
                                                id="expecteddeldate{{i}}" name="expecteddeldate{{i}}"
                                                [(ngModel)]="item.ExpectedDeliveryDate"
                                                #expecteddeldate="ngModel"
                                                >
                                            <div class="invalid-feedback">
                                                <div *ngIf="item.ExpectedDeliveryDate">
                                                    Please enter date
                                                </div>

                                            </div>

                                        </td> -->
                                        <td>
                                            {{item.Variant1_Discount}}
                                        </td>
                                        <td>
                                            {{item.Variant2_Discount}}
                                        </td>
                                        <td>
                                            {{item.Variant3_Discount}}
                                        </td>
                                        <td>
                                            {{item.Variant4_Discount}}
                                        </td>
                                        <td style="width:75px; vertical-align:middle;">
                                            <input type="number" class="form-control p-1" min="1" step="1"
                                                (change)="onPOQuatityChange(i)" value="0" name="Quantity{{i}}"
                                                maxlength="9" style="text-align:left;" [disabled]="!isSOEditable"
                                                [(ngModel)]="item.POQuantity"
                                                (keypress)="AvoidSpace($event);isintNumberKey($event);">
                                            <div *ngIf="(item.QuantityValidation)">
                                                <span class="validationmsg">
                                                    Quantity is not valid</span>
                                            </div>
                                            <div *ngIf="(item.QuantityRangeValidation)">
                                                <span class="validationmsg">
                                                    Quantity should be in between 0 to 1000</span>
                                            </div>
                                        </td>
                                        <td
                                            style="width:100px; vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                            <input type="text" class="form-control p-1" maxlength="15" min="0"
                                                pattern="[0-9]+" id="pounitprice{{i}}" name="pounitprice{{i}}"
                                                [disabled]="!isSOEditable" [(ngModel)]="item.POUnitPrice"
                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                (change)="onPOUnitPriceChange(i)" #pounitprice="ngModel"
                                                [ngClass]="{ 'is-invalid': item.PoUnitPriceValidation }">
                                            <div class="invalid-feedback">
                                                <div *ngIf="item.PoUnitPriceValidation">
                                                    PO unit price must be an integer
                                                </div>

                                            </div>

                                        </td>

                                        <td style="vertical-align:middle;">
                                            <input type="number" class="form-control p-2 m-input" min="0"
                                                style="text-align:left;" name="podiscount{{i}}" max="99.99" step="0.01"
                                                value="0" [disabled]="!isSOEditable" [(ngModel)]="item.PODiscount"
                                                (change)="onPODiscountChange(i)"
                                                (keypress)="AvoidSpace($event);isNumberKey($event);allowDecimal($event)">
                                        </td>
                                        <td
                                            style="width:100px; vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                            <input class="form-control p-1" min="0" maxlength="20" id="povalue{{i}}"
                                                pattern="[0-9]+" type="text" style="text-align:left;"
                                                [ngClass]="{ 'is-invalid': item.QUotationValueValidation }"
                                                [(ngModel)]="item.ProductModelPOValue" name="povalue{{i}}"
                                                (keypress)="AvoidSpace($event);isNumberKey($event);" disabled>
                                            <div class="invalid-feedback">
                                                <div *ngIf="item.QUotationValueValidation">
                                                    PO Value must be an
                                                    integer</div>
                                            </div>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Special Package Discount(%):</label>

                            <div class="col-lg-3">

                                <input type="number" class="form-control m-input" min="0" style="text-align:left;"
                                    name="salesorderspecialpkfdiscount" max="99.99" step="0.01" value=0
                                    [disabled]="!isSOEditable"
                                    [(ngModel)]="EnquiryObj.EnquiryDetails.SalesOrderSpecialPkgDiscount"
                                    (change)="onSalesOrderSpecialPkgDiscountChange()"
                                    (keypress)="AvoidSpace($event);isNumberKey($event);allowDecimal($event)">

                                <div style="white-space: nowrap">
                                    {{EnquiryObj.POValues[0]?.Special_pkg_Discount}}% <span
                                        style="display: inline-block;">
                                        <h6> - From Quotation</h6>
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <h5
                                style="border-bottom: 1px solid #ccc; margin-top: 15px;padding-bottom: 10px; width: 100%;">
                                Other Charges Details</h5>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-4 col-form-label">Include transfer charges, packaging and forwarding
                                and other taxes in total po value :
                            </label>

                            <div class="col-lg-1 pt-3">
                                <mat-checkbox name="includeallcharges" #includeallcharges="ngModel"
                                    class="larger-checkbox" [disabled]="!isSOEditable"
                                    [(ngModel)]="EnquiryObj.EnquiryDetails.IsIncludedAllSOCharges"
                                    (change)="calculateTotalPriceWithAllSOCharges(EnquiryObj.EnquiryDetails.IsIncludedAllSOCharges)">
                                </mat-checkbox>
                            </div>

                            <div class="offset-1"></div>

                            <label class="col-lg-3 col-form-label">Final PO Value including all charges:
                            </label>
                            <div class="col-lg-2 textSize">
                                <!-- <b>₹{{totalCalculatedPriceAfterIncludingAllCharges|
                                    indianCurrency}}/-</b> -->
                                <b>₹{{totalCalculatedPriceOfAllProductModel|
                                    indianCurrency}}/-</b>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col col-form-label" style="white-space:nowrap">Packaging and forwarding
                                (₹):<span style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col">
                                <input class="form-control m-input" type="number" name="packagingandforward" min="0"
                                    value="0" [(ngModel)]="EnquiryObj.EnquiryDetails.PackageAndForward"
                                    #packagingandforward="ngModel"
                                    [ngClass]="{ 'is-invalid':myform7.submitted  && packagingandforward.invalid || packagingandforward?.touched && packagingandforward.invalid}"
                                    maxlength="7" pattern="[0-9]+" style="text-align:right;"
                                    (change)="calculateTotalPriceWithAllSOCharges(EnquiryObj.EnquiryDetails.IsIncludedAllSOCharges)"
                                    (keypress)="AvoidSpace($event);isNumberKey($event);" [disabled]="!isSOEditable"
                                    required>
                                <div class="invalid-feedback"
                                    *ngIf="myform7.submitted && packagingandforward.invalid || packagingandforward?.touched && packagingandforward.invalid">
                                    <div *ngIf="myform7.hasError('required', 'packagingandforward')">
                                        Packaging and forwarding price is required</div>
                                    <div *ngIf="myform7.hasError('pattern', 'packagingandforward')">
                                        Packaging and forwarding price must be an integer</div>
                                </div>
                            </div>

                            <label class="col col-form-label" style="white-space:nowrap">Transfer charges (₹):<span
                                    style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col">
                                <input class="form-control m-input" type="number" name="transfercharge" min="0"
                                    value="0" [(ngModel)]="EnquiryObj.EnquiryDetails.TransferCharge"
                                    #transfercharge="ngModel"
                                    [ngClass]="{ 'is-invalid':myform7.submitted  && transfercharge.invalid || transfercharge?.touched && transfercharge.invalid}"
                                    maxlength="7" pattern="[0-9]+" style="text-align:right;"
                                    (change)="calculateTotalPriceWithAllSOCharges(EnquiryObj.EnquiryDetails.IsIncludedAllSOCharges)"
                                    (keypress)="AvoidSpace($event);isNumberKey($event);" [disabled]="!isSOEditable"
                                    required>
                                <div class="invalid-feedback"
                                    *ngIf="myform7.submitted && transfercharge.invalid || transfercharge?.touched && transfercharge.invalid">
                                    <div *ngIf="myform7.hasError('required', 'transfercharge')">
                                        Transfer charge price is required</div>
                                    <div *ngIf="myform7.hasError('pattern', 'transfercharge')">
                                        Transfer charge price must be an integer</div>
                                </div>
                            </div>

                            <label class="col col-form-label" style="white-space:nowrap; text-align: right;">Other taxes
                                (₹):<span style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col">
                                <input class="form-control m-input" type="number" name="othertaxes" min="0" value="0"
                                    [(ngModel)]="EnquiryObj.EnquiryDetails.OtherTax" #othertaxes="ngModel"
                                    [ngClass]="{ 'is-invalid':myform7.submitted  && othertaxes.invalid || othertaxes?.touched && othertaxes.invalid}"
                                    maxlength="7" pattern="[0-9]+" style="text-align:right;"
                                    (change)="calculateTotalPriceWithAllSOCharges(EnquiryObj.EnquiryDetails.IsIncludedAllSOCharges)"
                                    (keypress)="AvoidSpace($event);isNumberKey($event);" [disabled]="!isSOEditable"
                                    required>
                                <div class="invalid-feedback"
                                    *ngIf="myform7.submitted && othertaxes.invalid || othertaxes?.touched && othertaxes.invalid">
                                    <div *ngIf="myform7.hasError('required', 'othertaxes')">
                                        Other tax price is required</div>
                                    <div *ngIf="myform7.hasError('pattern', 'othertaxes')">
                                        Other tax price must be an integer</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <h5
                                style="border-bottom: 1px solid #ccc; margin-top: 15px;padding-bottom: 10px; width: 100%;">
                                OA Terms and Condition Details</h5>
                        </div>

                        <div class="form-group m-form__group row">
                            <div class="table-responsive">
                                <table class="table order-list table-striped- table-bordered table-hover table-edit"
                                    id="tcTable">
                                    <thead>
                                        <tr>
                                            <th class="text-center">
                                                OA Terms & Condition<span style="color:#f4516c;">&nbsp;*</span>
                                            </th>
                                            <th class="text-center">
                                                Value<span style="color:#f4516c;">&nbsp;*</span>
                                            </th>
                                            <th class="text-center" *ngIf="isSOEditable">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <ng-container
                                            *ngFor="let field of EnquiryObj.DynamicTCDetailObj ; let i = index;">
                                            <tr>
                                                <td>
                                                    <input type="text" class="form-control input-sm socss"
                                                        id="TndCName{{i}}" name="TndCName{{i}}"
                                                        [(ngModel)]="field.TndCName" #TndCName="ngModel"
                                                        placeholder="Enter OA Terms & Condition"
                                                        [disabled]="!isSOEditable" required>
                                                    <div *ngIf="(field.TndCNameValidation)">
                                                        <span class="validationmsg">
                                                            Term is required
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control input-sm socss"
                                                        #TndCValue="ngModel" id="TndCValue{{i}}" name="TndCValue{{i}}"
                                                        [(ngModel)]="field.TndCValue" placeholder="Enter Value"
                                                        [disabled]="!isSOEditable" required>
                                                    <div *ngIf="(field.TndCValueValidation)">
                                                        <span class="validationmsg">
                                                            Value is required
                                                        </span>
                                                    </div>
                                                </td>
                                                <td *ngIf="isSOEditable" style="padding:5px!Important;">
                                                    <div style="display: inline-flex;">

                                                        <a title="Add" (click)="AddNewRow(i)"
                                                            *ngIf=" i == EnquiryObj.DynamicTCDetailObj.length - 1"
                                                            style="color:#000000;width: 25px;height: 25px;"
                                                            class="btn btn-primary m-btn m-btn--icon m-btn--icon-only mr-2"><i
                                                                class="fa fa-plus"></i></a>

                                                        <a title="Delete"
                                                            *ngIf="(EnquiryObj.DynamicTCDetailObj.length>1) && ((EnquiryObj.DynamicTCDetailObj.length - 1 ) == i)"
                                                            (click)="deleterow(i)"
                                                            style="color:#ffffff;width: 25px;height: 25px;"
                                                            class="btn btn-metal m-btn m-btn--icon m-btn--icon-only mr-2"><i
                                                                class="fa fa-minus"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div *ngIf="isSOEditable" class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Comments:<span
                                    style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" id="eccomments" name="eccomments" rows="3"
                                    maxlength="1500" [(ngModel)]="EnquiryObj.EnquiryDetails.Comments"
                                    (keyup)="limitTextOnKeyUpDown1($event)" (keypress)="limitTextOnKeyUpDown1($event)"
                                    #eccomments="ngModel"
                                    [ngClass]="{ 'is-invalid': (myform7.submitted && eccomments.invalid) || (eccomments?.touched && eccomments.invalid) }"
                                    required>
                                                </textarea>
                                <span> Limit {{maxLimit}} characters,
                                    {{remLimit}} characters
                                    remaining.</span>
                                <div *ngIf="(myform7.submitted && eccomments.invalid) || (eccomments?.touched && eccomments.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="eccomments.errors.required">
                                        Comment is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="button-row text-right mt-4" style="margin:15px;">
                    <div class="m-portlet__head-caption">
                        <button class="btn btn-primary ml-auto js-btn-next btnspc js-btn-next mr-3"
                            *ngIf="(EnquiryObj.EnquiryDetails.Status == enquiryStatus.EnquiryOrderConfirmedbySalesEngg 
                                        || EnquiryObj.EnquiryDetails.Status == enquiryStatus.OrderApprovedbyMngmt_SalesDirector
                                        || EnquiryObj.EnquiryDetails.Status == enquiryStatus.OrderApprovedbyMngmt_CEO) && !UpdatePO && (role==AppRoles.CustomerService || role==AppRoles.Admin)"
                            type="submit" (click)="EditPO()">
                            Edit PO</button>
                        <span *ngFor="let item of workflowActionList;let i = index;">
                            <button class="btn btn-primary ml-auto js-btn-next btnspc"
                                type="{{item.workflowActionType}}"
                                (click)="OnWorkflowAction(myform7,item.workflowActionId)">
                                {{item.workflowActionName}}
                            </button> &nbsp; &nbsp;
                        </span>
                        <!-- <button *ngIf = "this.UserRole == roleOfAdmin"
                            class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                        (click)="SendBack()">Send Back</button> &nbsp; &nbsp;&nbsp; &nbsp; -->
                        <button class="btn btn-metal m-btn m-btn--air m-btn--custom" type="button"
                            (click)="backtoList()" routerLinkActive="active">Cancel</button>
                    </div>

                </div>
            </div>
        </div>
    </form>
</div>